import React from 'react';
import styles from './FinAnalytics.module.css';

const FinAnalytics = () => {
    return (
        <div className={styles.reportsContainer}>
            <h2>Reports Content</h2>
            <p>This is where the reports details will be displayed.</p>
        </div>
    );
};

export default FinAnalytics;
