import React from "react";
import styles from "./Lfeedback.module.css";
import { useParams } from "react-router-dom";

const Lfeedback = (props) => {
 
  const {brands, Para} = props;
    
  

  return (
    <>
      {/**Select and chat with the Influencer directly */}
      <div className={styles.feedbackSection}>
        <div className={styles.LeftPanel}>
          <div className={styles.feedbackPanel}>
            <div className={styles.header}>
              <span>Looking for feedback?</span>
              <div className={styles.toggleSwitch}>
                <input
                  type="checkbox"
                  id="switch"
                  className={styles.switchInput}
                />
                <label htmlFor="switch" className={styles.switchLabel}></label>
              </div>
            </div>
            <div className={styles.feedbackList}>
              <div className={styles.feedbackItem}>
                <img
                  src="https://img.freepik.com/premium-photo/man-wearing-glasses-purple-pink-background-with-picture-man-wearing-glasses_1103290-66109.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1728172800&semt=ais_hybrid"
                  alt="Buzz"
                  className={styles.avatar}
                />
                <div className={styles.userDetails}>
                  <span className={styles.userName}>Buzz Usborne</span>
                  <span className={styles.status}>Pending</span>
                </div>
              </div>
              <div className={styles.feedbackItem}>
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXupVpRdgE9Xm4i6wQ6R0cObxBWYFKWyNG2kwVgpCv1NKsrBWOMDDiidnjdPUY9A4JBK4&usqp=CAU"
                  alt="Gabriel"
                  className={styles.avatar}
                />
                <div className={styles.userDetails}>
                  <span className={styles.userName}>Gabriel Valdivia</span>
                  <span className={styles.status}>Done</span>
                </div>
              </div>
              <div className={styles.feedbackItem}>
                <img
                  src="https://img.freepik.com/premium-vector/female-user-profile-avatar-is-woman-character-screen-saver-with-emotions_505620-617.jpg?w=360"
                  alt="Jochem"
                  className={styles.avatar}
                />
                <div className={styles.userDetails}>
                  <span className={styles.userName}>Jochem Dierx</span>
                  <span className={styles.status}>Requested</span>
                </div>
              </div>

              <div className={styles.addmoreBtn}>
                <button className={styles.addMoreBtn}>+</button>
                <div className={styles.addmoreText}>
                  <p> Add More</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.RightPanel}>
          <div className={styles.feedbackInfo}>
            <span className={styles.feedbackTag}>Feedback</span>
            <h2>Select and chat with the {brands} directly</h2>
            <p>
              {Para}
            </p>
            <button className={styles.howItWorksBtn}>
              <span>&#9654;See how it works</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Lfeedback;
