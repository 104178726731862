import React from "react";
import styles from "./Brand.module.css";

import Newsletter from "../LandingPage/Newsletter/Newsletter";
import ceat from "../../assets/brands/ceat.png";
import ajio from "../../assets/brands/ajio.png";
import dbs from "../../assets/brands/dbs.png";
import tata from "../../assets/brands/tata.png";
import magicbrush from "../../assets/brands/magicbrush.png";
import gym from "../../assets/brands/gym.png";
import studio from "../../assets/brands/studio.png";
import metrix1 from "../../assets/metrix1.png";
import metrix2 from "../../assets/metrix2.png";
import DashboardView from "../../assets/brands/DashboardView.png";
import Scroller from "../Creator/Scroller/Scroller";
import Lfeedback from "../Creator/Lfeedback/Lfeedback";
const Brand = () => {
  const brands = "Influencer";
    const Para =
      "Build authentic relationships with influencers on Inggage. Our direct chat feature makes it easy to connect, collaborate, and negotiate terms. Making every collaboration tailored to your needs.";
  return (
    <>
      <div className={styles.BrandContainer}>
        {/** hero section of Brand Page */}
        <div className={styles.BrandHeroPage}>
          <h1>Pay only for what works</h1>
          <p>
            Our AI powered platform allows you to streamline influencer
            marketing in simple workflows. We allow you to source, chat, bucket,
            campaign and pay as you go, making your ROI the centre of every
            campaign.
          </p>
          <button className={styles.StartButton1}>Start for Free</button>
          <div className={styles.GetInTouch}>
            <p>
              Want to talk or get a live demo?{" "}
              <a href="#contact"> Get in touch →</a>
            </p>
          </div>
        </div>

        {/**Brand Icon page */}
        <div className={styles.BrandIcons}>
          <div className={styles["brands-logos"]}>
            <div className={styles["brand-logo"]}>
              <img src={ceat} alt="Logo 1" />
            </div>
            <div className={styles["brand-logo"]}>
              <img src={ajio} alt="Logo 2" />
            </div>
            <div className={styles["brand-logo"]}>
              <img src={dbs} alt="Logo 3" />
            </div>
            <div className={styles["brand-logo"]}>
              <img src={magicbrush} alt="Logo 4" />
            </div>
            <div className={styles["brand-logo"]}>
              <img src={tata} alt="Logo 5" />
            </div>
            <div className={styles["brand-logo"]}>
              <img src={gym} alt="Logo 6" />
            </div>
            <div className={styles["brand-logo"]}>
              <img src={studio} alt="Logo 7" />
            </div>
          </div>
        </div>

        <div>
          <Scroller />
        </div>

        {/** Select and chat with the Influencer directly */}
        <Lfeedback brands={brands} Para={Para}/>
        {/** Dashboard View left right */}

        <div className={styles.DashBoardView}>
          <div className={styles.LeftPanelDash}>
            <button className={styles.shareButton}>Share</button>
            <h1 className={styles.mainHeading}>
              Track your Campaign <br /> with our Interactive Dashboard
            </h1>
            <p className={styles.description}>
              Gain valuable insights into your influencer marketing campaigns
              with our intuitive dashboard. Easily visualize key metrics, track
              performance trends, and make data-driven decisions to optimize
              your strategy.
            </p>
            
            <button className={styles.howItWorksBtn}>
              <span>&#9654;See how it works</span>
            </button>
          </div>

          <div className={styles.RightPanelDash}>
            <img src={DashboardView} alt="Dashboard View" />
          </div>
        </div>

        {/** Matrix Page two images of default metrix */}
        <div className={styles.MetrixContainer}>
          <div>
            <h1>Show All the Metrices</h1>
          </div>
          <div className={styles.MetrixBox}>
            <div className={styles.metricCard}>
              <h3>Projects</h3>
              <p>Organize, follow, and archive.</p>
              <div className={styles.metricImage}>
                <img src={metrix1} alt="Project metrics graph" />
              </div>
            </div>

            <div className={styles.metricCard}>
              <h3>Member profiles</h3>
              <p>Automatic portfolios of your work.</p>
              <div className={styles.metricImage}>
                <img src={metrix2} alt="Member profiles graph" />
              </div>
            </div>
          </div>
        </div>

        {/* What Brand says about Inggage */}
        <div className={styles.BrandSaysContainer}>
          <div className={styles.testimonial}>
            <img
              src="https://cdn-1.webcatalog.io/catalog/three-sigma/three-sigma-icon-filled-256.webp?v=1721871020932"
              alt="Logo 1"
              className={styles.companyLogo}
            />
            <p className={styles.testimonialText}>
              Inggage has been a game-changer for our influencer marketing
              campaigns. The platform's user-friendly interface and powerful
              analytics tools have helped us identify the most effective
              influencers and track our campaign's performance in real time. The
              pay-as-you-go model has also been a huge benefit, allowing us to
              optimize our spending and maximize our ROI.
            </p>
            <div className={styles.userDetails}>
              <div>
                <img
                  src="https://img.freepik.com/premium-vector/young-man-anime-style-character-vector-illustration-design-manga-anime-boy_147933-4763.jpg"
                  alt="Gabriel Valdivia"
                  className={styles.avatar}
                />
              </div>
              <div className={styles.Companyser}>
                <span className={styles.userName}>Gabriel Valdivia</span>
                <span className={styles.userTitle}>
                  Principal Product Designer, Patreon
                </span>
              </div>
            </div>
          </div>

          <div className={styles.testimonial}>
            <div>
              <img
                src="https://s.cafebazaar.ir/images/icons/com.kooth.serenity-60c4de94-b127-44af-9ed6-99e8a971e958_512x512.png?x-img=v1/format,type_webp,lossless_false/resize,h_256,w_256,lossless_false/optimize"
                alt="Logo 2"
                className={styles.companyLogo}
              />
            </div>
            <p className={styles.testimonialText}>
              As a small business owner, I was hesitant to invest in influencer
              marketing due to the high upfront costs. Inggage changed that.
              Their pay-as-you-go model allowed me to try influencer marketing
              without breaking the bank. I was able to track my campaign's
              performance in real time and make data-driven decisions to
              optimize my spending. Thanks to Inggage, I've seen a significant
              increase in brand awareness and website traffic without
              overspending.
            </p>
            <div className={styles.userDetails}>
              <div>
                <img
                  src="https://cdn.pixabay.com/photo/2024/04/16/21/11/ai-generated-8700791_1280.jpg"
                  alt="Buzz Usborne"
                  className={styles.avatar}
                />
              </div>
              <div className={styles.Companyser}>
                <span className={styles.userName}>Buzz Usborne</span>
                <span className={styles.userTitle}>
                  Principal Designer, Buildkite
                </span>
              </div>
            </div>
          </div>
        </div>

        {/** join our Newsletter */}
        <div className={styles.Newsletter}>
          <div>
            <Newsletter />
          </div>
        </div>
      </div>
    </>
  );
};

export default Brand;
