import axios from 'axios'
import baseUrl from './universalApi';

const API = axios.create({ baseURL: baseUrl });


export const logInWithGoogle= (email)=> API.post('/auth1/loginInfluencerWith_Google',{email});
export const logInWithPass= (formData)=> API.post('/auth1/loginInfluencerWith_Password',formData);
export const signUpInflu = (formData) => API.post('/auth1/signupInfluencer', formData);

export const brandLoginWithGoogle = (email) => API.post('/auth2/loginBrandWith_Google', email);
export const brandWithPass = (formData) => API.post('/auth2/loginBrandWith_Password', formData);
export const signUpBrand = (formData) => API.post('/auth2/signupBrand', formData);
  
export const DeleteUser = (id) => API.delete('/auth1/deleteCreator',{
    data: { id } 
});

export const getInstaInfo = (Data) => API.post('/api/accesstoken', Data);
//Get in touch form
export const ContactUs = (formData) => API.post('/api/contact', formData);