import React, { useState } from "react";
import InfluencerLogin from "./InfleuncerLogin/InfluencerLogin";
import BrandLogin from "./BrandLogin/BrandLogin";
import styles from "./Login.module.css";
//import formImage from "../../assets/formImage.png";
//import {  useNavigate } from "react-router-dom";
import inggage from '../../assets/inggage.png'

const Login = () => {
  const [isBrand, setIsBrand] = useState(false);

  const toggleSwitch = () => {
    setIsBrand(!isBrand);
  };

  return (
    <div className={styles.LoginForm}>
      <div className={styles.formImage}>
        <img src={inggage} alt="form" />
      </div>
      <div className={styles.ToggleForms}>
        <div className={styles.toggleContainer}>
          <span className={!isBrand ? styles.activeLabel : styles.inactiveLabel}>
            Creator
          </span>
          <div className={styles.switch} onClick={toggleSwitch}>
            <div className={`${styles.slider} ${isBrand ? styles.sliderActive : ""}`} />
          </div>
          <span className={isBrand ? styles.activeLabel : styles.inactiveLabel}>
            Brand
          </span>
          <div>
        
        </div>
        </div>
        
        <div className={styles.toggleLogin}>
          {isBrand ? <BrandLogin /> : <InfluencerLogin />}
          
        </div>
        
      </div>
    </div>
  );
};

export default Login;
