/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import styles from "./Talent.module.css";
import filteroptions from "./filteroptions";
import { useDispatch } from "react-redux";
import "aos/dist/aos.css";
import bannerImage from "../../assets/images/banner.gif";
import { FaBookmark } from "react-icons/fa";
import { BsDatabaseFill } from "react-icons/bs";
import toast, { Toaster } from "react-hot-toast";
import { RequestedCreator } from "../../actions/BrandActions";
import { useNavigate } from 'react-router-dom';


const Talent = ({ creator, isBrand, brandData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [requestedArray, setRequestedArray] = useState([]);
  const [requestData, setRequestData] = useState({
    brandID: "",
    creatorID: "",
    creatorEmail: "",
    creatorName: "",
    creatorImg: "",
    creatorNiche: [],
    createdAt: "",
  });

  useEffect(() => {
    if (requestedArray.length > 0) {
      console.log("Updated requestedArray:", requestedArray);
    }
  }, [requestedArray]);

  const handleCardClick = async (profile) => {
    // console.log(creatorID)

    if (isBrand) {
      const requestPayload = {
        brandID: brandData.user._id,
        creatorID: profile._id,
        creatorEmail: profile.email,
        creatorName: profile.displayname,
        creatorImg: profile.profile_image,
        creatorNiche: profile.niche,
        createdAt: Date.now(),
      };

      // Update the state with the new request data
      setRequestData(requestPayload);
      // console.log(requestPayload);
      try {
        const res = await dispatch(RequestedCreator(requestPayload));
        console.log(res);
      } catch (error) {
        console.log(error);
      }
      setRequestedArray((prev) => [...prev, requestPayload]);
    } else {
      toast.error("You are not a Brand", {
        duration: 3000,
      });
    }
    //console.log(requestedArray);
    //setRequest(!request)
    //setSelectedProfile(profile);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
  };

  const filteredProfiles = creator.filter((profile) => {
    const matchesSearchQuery = profile.displayname
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesFilter = selectedFilter
      ? profile.niche.includes(selectedFilter)
      : true;

    return matchesSearchQuery && matchesFilter;
  });

  const filterOptions = filteroptions;



  const handleProfileClick = (profile) => {
   //console.log(profile)
    navigate(`/influencer/${profile._id}`, { state: { profile } });
  };
  return (
    <>
      <Toaster />
      <div className={styles.talentPage}>
        {/* Image at the top */}
        <div className={styles.banner}>
          <img
            src={bannerImage} // Replace with the actual URL
            alt="Top Banner"
            className={styles.bannerImg}
          />
        </div>

        {/* Heading and Search Bar */}
        <div className={styles.searchHeader}>
          <div className={styles.subheader}>
            <h1 className={styles.heading}>Talent Page</h1>
            <p className={styles.subtitle}>
              The king, seeing how much happier his subjects were, realized the
              error of his ways and repealed the joke tax.
            </p>
          </div>
          <div className={styles.searchFilterContainer}>
            <input
              type="text"
              placeholder="Search your Creator"
              className={styles.searchInput}
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <select
              className={styles.filterDropdown}
              value={selectedFilter}
              onChange={handleFilterChange}
            >
              {filterOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Profile Cards Section */}
        <div className={styles.profileCardContainer}>
          {filteredProfiles.map((profile, index) => (
            <div className={styles.profileCard} key={index}>
              {/* Profile Image */}

              {/* Profile Details */}
              <div className={styles.profileDetails}>
                <div className={styles.TalentBanner}>
                  <img
                    src="https://static.vecteezy.com/system/resources/thumbnails/022/536/549/small/modern-banner-background-colorful-blue-and-purple-gradations-circles-eps-10-free-vector.jpg"
                    alt="banner"
                  />
                </div>
                <div className={styles.profileImage}>
                  <img
                    className={styles.ProfileImageHover}
                    src={profile.profile_image}
                    alt="ProfileImg"
                  />
                  {isBrand && (
                    <div className={styles.viewProfileText} onClick={()=>handleProfileClick(profile)}>View Profile</div>
                  )}
                </div>
                <h2 className={styles.profileName}>{profile.displayname}</h2>
                <div className={styles.NicheDiv}>
                  {profile.niche.map((niche, index) => (
                    <span key={index} className={styles.ProfileNiche}>
                      {niche}
                      {index < profile.niche.length - 1 && ", "}
                    </span>
                  ))}
                </div>
                <div className={styles.profileStats}>
                  <div className={styles.statsBlock}>
                    <p>Videos</p>
                    <h3>{profile.totalPost}</h3>
                  </div>
                  <div className={styles.statsBlock}>
                    <p>Followers</p>
                    <h3>{profile.followers}</h3>
                  </div>
                  <div className={styles.statsBlock}>
                    <p>ER%</p>
                    <h3>{profile.ER}</h3>
                  </div>
                </div>
                <div className={styles.profileActions}>
                  <button
                    className={styles.requestPriceButton}
                    onClick={() => handleCardClick(profile)}
                  >
                    Request Price
                  </button>
                  <div className={styles.buttonIcon}>
                    <div className={styles.saveToListButton}>
                      <FaBookmark />
                    </div>
                    <div className={styles.RecommendButton}>
                      <BsDatabaseFill />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Talent;
