import React, { useState, useEffect } from "react";
import "./InstagramButton.css";

const Insta = () => {
  const [facebookUserAccessToken, setFacebookUserAccessToken] = useState("");
  const [instaid, setInstaId] = useState(null);

  /* --------------------------------------------------------
   *              FACEBOOK LOGIN AND INITIAL SETUP
   * --------------------------------------------------------
   */

  // Check if access token is in localStorage when the component mounts
  useEffect(() => {
    const storedAccessToken = localStorage.getItem("FacebookUserAccessToken");
    const storedInstaId = localStorage.getItem("InstaId");

    // window.FB.getLoginStatus((response) => {
    //   if (response.status === "connected") {
    //     const accessToken = response.authResponse?.accessToken;
    //     setFacebookUserAccessToken(accessToken);
    //     localStorage.setItem("FacebookUserAccessToken", accessToken);
    //   }
    // });
    if (storedAccessToken) {
      setFacebookUserAccessToken(storedAccessToken);
    } else {
      window.FB.getLoginStatus((response) => {
        if (response.status === "connected") {
          const accessToken = response.authResponse?.accessToken;
          setFacebookUserAccessToken(accessToken);

          const res = localStorage.setItem(
            "FacebookUserAccessToken",
            accessToken
          );
        }
      });
    }

    if (storedInstaId) {
      setInstaId(storedInstaId);
    }

    // Trigger the chain of function calls if the user is already logged in
  }, [facebookUserAccessToken]);

  const logInToFB = async () => {
    window.FB.login(
      (response) => {
        const accessToken = response.authResponse?.accessToken;
        if (accessToken) {
          setFacebookUserAccessToken(accessToken);
          localStorage.setItem("FacebookUserAccessToken", accessToken);
        }
        passToken(accessToken);
      },
      {
        scope: "instagram_basic,pages_show_list",
      }
    );
  };

  const passToken = async (token) => {
    const userId = "66fbf6729fe0a92b534974ab";
    try {
      const response = await fetch("http://localhost:4000/api/accesstoken", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, userId }), // Send the token as part of the body
      });

      // Handle the response from the backend
      const data = await response.json();
      if (response.ok) {
        //console.log("Access token sent successfully:", data);
        setFacebookUserAccessToken(data.longLivedToken);
      } else {
        console.error("Error sending access token:", data);
      }
    } catch (error) {
      console.error("Request failed:", error);
    }
  };
  const logOutOfFB = () => {
    window.FB.logout(() => {
      localStorage.clear(); // Clear all localStorage data
      setFacebookUserAccessToken(null);
    });
  };

  /* --------------------------------------------------------
   *             FACEBOOK PAGES AND INSTAGRAM ID
   * --------------------------------------------------------
   */
  const getFacebookPages = () => {
    return new Promise((resolve) => {
      window.FB.api(
        "me/accounts",
        { access_token: facebookUserAccessToken },
        (response) => {
          resolve(response.data);
        }
      );
    });
  };

  const getInstagramAccountId = (facebookPageId) => {
    return new Promise((resolve) => {
      window.FB.api(
        facebookPageId,
        {
          access_token: facebookUserAccessToken,
          fields: "instagram_business_account",
        },
        (response) => {
          resolve(response.instagram_business_account?.id);
        }
      );
    });
  };

  const getInstaId = async () => {
    const storedInstaId = localStorage.getItem("InstaId");

    if (storedInstaId) {
      setInstaId(storedInstaId);
    } else {
      const facebookPages = await getFacebookPages();
      const instagramAccountId = await getInstagramAccountId(
        facebookPages[0].id
      );
      setInstaId(instagramAccountId);
      localStorage.setItem("InstaId", instagramAccountId);
    }
  };

  /* --------------------------------------------------------
   *              FETCH INSTAGRAM INSIGHTS
   * --------------------------------------------------------
   */

  return (
    <div className="instagram-button">
      <section className="app-section">
        <h3>1. Log in with Facebook</h3>
        {facebookUserAccessToken ? (
          <>
            <button onClick={logOutOfFB} className="btn action-btn mx-3">
              Log out of Facebook
            </button>
          </>
        ) : (
          <button onClick={logInToFB} className="btn action-btn">
            Log in to Facebook
          </button>
        )}
      </section>
    </div>
  );
};

export default Insta;
