import axios from "axios";
import baseUrl from './universalApi';

const API = axios.create({ baseURL: baseUrl });



API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
  
    return req;
  });
 
export const getCreator = (userId) => API.get(`/influencer/${userId}`);
export const getallCreators = () => API.get(`/influencers/getAllCreators`);

export const updateUser = (id, formData) =>  API.put(`/influencers/updateInfluencer/${id}`, formData);



