import React, { useState } from "react";
import styles from "./UpdateInfluencer.module.css";

import Account from "./Account/Account";
import About from "./About/About";
import Contact from "./Contacts/Contacts";
import Interests from "./Interests/Interests";
import Preferences from "./Preferences/Preferences";
import Settings from "./Settings/Settings";
import { useSelector } from "react-redux";

const UpdateInfluencer = () => {
  // State to manage the selected panel

  const [activePanel, setActivePanel] = useState("Account");
  const authData = useSelector((state) => state.auth.authData.user);

  // Handlers for button clicks to change active panel
  const handlePanelClick = (panel) => {
    setActivePanel(panel);
  };

  return (
    <div className={styles.profileContainer}>
      {/* Left side - Profile picture and Basic Info */}
      <div className={styles.leftPanel}>
        <div></div>
        <div className={styles.profilePicture}>
          <img src={authData.profile_image} alt="Profile" />
        </div>
        <div className={styles.basicInfo}>
          <h2>{authData.displayname}</h2>
          <p>{authData.bio}</p>

          {/* Add Buttons for each panel */}
          <div className={styles.buttonGroup}>
            <button
              onClick={() => handlePanelClick("Account")}
              className={activePanel === "Account" ? styles.activeButton : ""}
            >
              Account Info
            </button>
            <button
              onClick={() => handlePanelClick("About")}
              className={activePanel === "About" ? styles.activeButton : ""}
            >
              About
            </button>
            <button
              onClick={() => handlePanelClick("Contact")}
              className={activePanel === "Contact" ? styles.activeButton : ""}
            >
              Contact
            </button>
            <button
              onClick={() => handlePanelClick("Interests")}
              className={activePanel === "Interests" ? styles.activeButton : ""}
            >
              Interests
            </button>
            <button
              onClick={() => handlePanelClick("Preferences")}
              className={
                activePanel === "Preferences" ? styles.activeButton : ""
              }
            >
              Preferences
            </button>
            <button
              onClick={() => handlePanelClick("Settings")}
              className={activePanel === "Settings" ? styles.activeButton : ""}
            >
              Settings
            </button>
          </div>
        </div>
      </div>

      {/* Right side - Conditional Rendering */}
      <div className={styles.rightPanel}>
        {activePanel === "Account" && <Account authData={authData} />}
        {activePanel === "About" && <About user={authData}/>}
        {activePanel === "Contact" && <Contact />}
        {activePanel === "Interests" && <Interests />}
        {activePanel === "Preferences" && <Preferences />}
        {activePanel === "Settings" && <Settings />}
      </div>
    </div>
  );
};

export default UpdateInfluencer;
