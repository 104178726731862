import React, { useState } from "react";
import styles from "./Requests.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteRequestedInfluencer } from "../../../actions/BrandActions"; // Import the delete action

const Requests = ({ requestedCreator }) => {
  const dispatch = useDispatch();

  // Initial state for influencers data
  const requests = useSelector((state) => state.creators.creators);
  const navigate = useNavigate();

  const [influencers, setInfluencers] = useState(requestedCreator);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [formData, setformData] = useState({
    brandID: "",
    creatorID: "",
  });
  // Function to open the modal with influencer data
  const viewProfile = (influencer) => {
    setSelectedInfluencer(influencer);
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedInfluencer(null);
  };

  // Function to delete an influencer
  const deleteInfluencer = async (creatorID, brandID) => {
    if (!brandID) {
      console.error("Brand ID is required.");
      return;
    }
   
    // Dispatch action to delete the influencer
   // Dispatch action to delete the influencer
   try {
    await dispatch(deleteRequestedInfluencer(brandID, creatorID))
        .then(() => {
            // Remove influencer from local state if deletion is successful
            setInfluencers((prevInfluencers) => 
                prevInfluencers.filter((influencer) => influencer.creatorID !== creatorID)
            );
            console.log("Influencer deleted successfully.");
        });
} catch (error) {
    console.error("Error deleting influencer:", error);
}
  };

  const handleLaunchCampaign = () => {
    navigate("/createcampaign");
  };

  return (
    <div className={styles.reportsContainer}>
      <h4>Requested Influencers</h4>

      <ul className={styles.influencerList}>
        {influencers.map((influencer, index) => (
          <li key={index} className={styles.influencerItem}>
            <div className={styles.influencerCard}>
              <img
                src={influencer.creatorImg}
                alt={influencer.creatorName}
                className={styles.creatorImg}
              />
              <div className={styles.influencerInfo}>
                <h4>{influencer.creatorName}</h4>
                <p>{influencer.creatorEmail}</p>
                <p>Niche: {influencer.creatorNiche.join(", ")}</p>
              </div>
              <div className={styles.buttons}>
                <button
                  className={styles.viewProfileButton}
                  onClick={() => viewProfile(influencer)}
                >
                  View Profile
                </button>
                <button
                  className={styles.deleteButton}
                  onClick={() =>
                    deleteInfluencer(influencer.creatorID, influencer.brandID)
                  }
                >
                  Delete
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {/* Modal for showing detailed influencer info */}
      {selectedInfluencer && (
        <div className={styles.modalOverlay} onClick={closeModal}>
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <h3>{selectedInfluencer.creatorName}</h3>
            <img
              src={selectedInfluencer.creatorImg}
              alt={selectedInfluencer.creatorName}
              className={styles.modalImage}
            />
            <p>Email: {selectedInfluencer.creatorEmail}</p>
            <p>Niche: {selectedInfluencer.creatorNiche.join(", ")}</p>
            <button
              className={styles.closeModalButton}
              onClick={handleLaunchCampaign}
            >
              Launch Campaign
            </button>
            <button className={styles.closeModalButton} onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Requests;
