import React, { useState } from "react";
import axios from "axios";
import { contactUs } from "../../actions/AuthActions";
import styles from "./Contact.module.css";
import { useDispatch, useSelector } from "react-redux";

const Contact = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    reasonForContact: "",
    context: "",
    hearAbout: "",
    consent: false,
  });

  const imageURL =
    "https://img.freepik.com/premium-vector/man-standing-front-computer-screen-with-website-pointing-it-paper-airplane-flying-plants-both-sides_657438-47150.jpg?w=740";
  // Handle change for input fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission (replace this with your backend logic)
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      dispatch(contactUs(formData));
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Form submission failed. Please try again later.");
    }
  };
  const [checked, setChecked] = useState(false);

  // Handle checkbox toggle
  const handleCheckboxChange = () => {
    setFormData({
      ...formData,
      consent: !formData.consent,
    });
  };

  return (
    <>
      <div className={styles.ContactContainer}>
        <div className={styles.ContactBox}>
          <div className={styles.FormContainer}>
            <h1 className={styles.heading}>LET'S CONNECT!</h1>
            <p className={styles.subheading}>
              We would love to hear from you so please get in touch.
            </p>

            <form className={styles.form} onSubmit={handleSubmit}>
              {/* Existing fields */}

              <div className={styles.field}>
                <label htmlFor="fullName">FULL NAME*</label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className={styles.field}>
                <label htmlFor="email">EMAIL*</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className={styles.field}>
                <label htmlFor="phoneNumber">PHONE NUMBER</label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>

              <div className={styles.field}>
                <label htmlFor="reason">REASON FOR CONTACT*</label>
                <select
                  id="reasonForContact"
                  name="reasonForContact"
                  value={formData.reasonForContact}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Please select</option>
                  <option value="General Inquiry">General Inquiry</option>
                  <option value="Support">Support</option>
                  <option value="Partnership">Partnership</option>
                </select>
              </div>

              <div className={styles.field}>
                <label htmlFor="context">PLEASE ADD SOME CONTEXT*</label>
                <textarea
                  id="context"
                  name="context"
                  value={formData.context}
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>

              {/* New Section for 'Where did you hear about influencer?' */}
              <div className={styles.field}>
                <label htmlFor="hearAbout">
                  WHERE DID YOU HEAR ABOUT INGGAGE?
                </label>
                <select
                  id="hearAbout"
                  name="hearAbout"
                  value={formData.hearAbout}
                  onChange={handleInputChange}
                >
                  <option value="">Please select</option>
                  <option value="Social Media">Social Media</option>
                  <option value="Website">Website</option>
                  <option value="Referral">Referral</option>
                </select>
              </div>

              {/* Consent and Data Privacy Section */}
              <div className={styles.consent}>
                <p>
                  We may use the personal data contained on this form in order
                  to conduct analysis and measurement regarding engagement with
                  this report, and to reach out to you with marketing
                  communications on subjects we think you may be interested.
                </p>
                <div className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    id="consent"
                    name="consent"
                    checked={formData.consent}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="consent">
                    I AGREE TO RECEIVE OTHER COMMUNICATIONS FROM INGGAGE.
                  </label>
                </div>
                
              </div>

              <button type="submit" className={styles.submitButton}>
                CONTACT US
              </button>
            </form>
          </div>
        </div>

        <div className={styles.ContactImage}>
          <img src={imageURL} alt="ContactImg"></img></div>
      </div>
    </>
  );
};

export default Contact;
