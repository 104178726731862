import axios from "axios";

import baseUrl from './universalApi';

const API = axios.create({ baseURL: baseUrl });

export const RequestedCreator = (CreatorData) => API.post(`/brand/requests`, CreatorData);



export const GetRequestedList = (id) => API.get(`/brand/getRequestList/${id}`);


export const deleteRequestedInfluencer = (id,creatorID) => API.post(`/brand/deleteRequestedInfluencer/${id}`, {creatorID:creatorID});
