import React from 'react'
import styles from './Stats.module.css'
import statsDash from '../../../assets/statsDash.png'

const Stats = () => {
  return (
    <div className={styles['stats-section']}>
      <img src={statsDash} alt='Demo'/>
    </div>
  )
}

export default Stats