import toast from "react-hot-toast";
import * as UploadApi from "../api/UploadRequest";

export const uploadImage = (id,Imgdata) => async (dispatch) => {
  dispatch({type: "UPDATING_START"})
  try {
    console.log("Image upload Action start ho gya hy")
   // console.log(data)
    const {data} =  await UploadApi.uploadImage(id,Imgdata);
    dispatch({type: "UPDATING_SUCCESS", data: data})
    toast.success("image uploaded");
  } catch (error) {
    console.log(error);
    dispatch({type: "UPDATING_FAIL"})
  }
};

export const updateUser=(id, formData)=> async(dispatch)=> {
  dispatch({type: "UPDATING_START"})
  try{
      const {data} = await UploadApi.updateUser(id, formData);
      console.log("Action ko receive hoa hy ye : ",data)
      dispatch({type: "UPDATING_SUCCESS", data: data})
  }   
  catch(error){
      dispatch({type: "UPDATING_FAIL"})
  }
}


export const updateAbout = (id,data) => async (dispatch) => {
  dispatch({ type: "UPLOAD_START" });
  try {
    console.log(id, data)
    const newAbout = await UploadApi.updateAbout(id, data);
    //console.log(newAbout)
    dispatch({ type: "UPLOAD_SUCCESS", data: newAbout.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPLOAD_FAIL" });
  }
};


export const updateContact = (id,data) => async (dispatch) => {
  dispatch({ type: "UPLOAD_START" });
  try {
    console.log(id, data)
    const newContact = await UploadApi.updateContact(id, data);
   console.log(newContact)
    dispatch({ type: "UPLOAD_SUCCESS", data: newContact.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPLOAD_FAIL" });
  }
};

export const updateInterests = (id,data) => async (dispatch) => {
  dispatch({ type: "UPLOAD_START" });
  try {
    console.log(id, data)
    const newInterest = await UploadApi.updateInterests(id, data);
  // console.log(newContact)
    dispatch({ type: "UPLOAD_SUCCESS", data: newInterest.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPLOAD_FAIL" });
  }
};

export const updatePreferences = (id,data) => async (dispatch) => {
  dispatch({ type: "UPLOAD_START" });
  try {
    console.log(id, data)
    const newpreferences = await UploadApi.updatePreferences(id, data);
  // console.log(newContact)
    dispatch({ type: "UPLOAD_SUCCESS", data: newpreferences.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPLOAD_FAIL" });
  }
};