import React from "react";
import styles from "./BrandsInfo.module.css";
import avatar from "../../../assets/images/avatar.png";

const BrandsInfo = () => {
  const brands = [
    {
      id: 1,
      name: "Brand 1",
      logo: "https://cdn-icons-png.flaticon.com/512/10909/10909290.png",
    }, // Replace with actual image paths
    {
      id: 2,
      name: "Brand 2",
      logo: "https://cdn-icons-png.flaticon.com/512/10909/10909290.png",
    },
    {
      id: 3,
      name: "Brand 3",
      logo: "https://cdn-icons-png.flaticon.com/512/10909/10909290.png",
    },
    {
      id: 4,
      name: "Brand 4",
      logo: "https://cdn-icons-png.flaticon.com/512/10909/10909290.png",
    },
    {
      id: 5,
      name: "Brand 5",
      logo: "https://cdn-icons-png.flaticon.com/512/10909/10909290.png",
    },
    {
      id: 6,
      name: "Brand 6",
      logo: "https://cdn-icons-png.flaticon.com/512/10909/10909290.png",
    },
    {
      id: 7,
      name: "Brand 7",
      logo: "https://cdn-icons-png.flaticon.com/512/10909/10909290.png",
    },
  ];
  const reviews = [
    {
      id: 1,
      name: "Suryansu Dash",
      brand: "Nike",
      review:
        "the examining or considering again of something in order to decide if changes are necessary",
      rating: 5.0,
      avatar: "https://via.placeholder.com/50",
    },
    {
      id: 2,
      name: "Suryansu Dash",
      brand: "Nike",
      review:
        "the examining or considering again of something in order to decide if changes are necessary",
      rating: 5.0,
      avatar: "https://via.placeholder.com/50",
    },
  ];

  return (
    <>
    
    
    <div className={styles.brandsContainer}>
      {brands.map((brand) => (
        <div key={brand.id} className={styles.brandBox}>
          <img src={brand.logo} alt={brand.name} className={styles.brandLogo} />
        </div>
      ))}
    </div>

      {/*   Review secction */}
      <div className={styles.campaignReviews}>
        <h2 className={styles.reviewsTitle}>Reviews</h2>
        <div className={styles.reviewsContainer}>
          {reviews.map((review) => (
            <div key={review.id} className={styles.reviewCard}>
              <div className={styles.reviewHeader}>
                <img src={avatar} alt={review.name} className={styles.avatar} />
                <div>
                  <h3 className={styles.reviewName}>{review.name}</h3>
                  <p className={styles.reviewBrand}>{review.brand}</p>
                </div>
              </div>
              <p className={styles.reviewText}>{review.review}</p>
              <div className={styles.reviewFooter}>
                <span className={styles.star}>⭐</span>
                <span>{review.rating}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BrandsInfo;
