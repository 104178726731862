import React, { useEffect, useState } from "react";
//import { io } from "socket.io-client";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const SOCKET_SERVER_URL = "ws://localhost:8081";
const API_SERVER_URL = "http://localhost:4000"; // Replace with your server URL



const InfluxDB = () => {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
  });

  const formattedData = data.map((entry) => {
    // Parse the date and validate
    const date = new Date(entry.timestamp);
    if (isNaN(date)) {
      console.warn(`Invalid timestamp detected: ${entry.timestamp}`);
      return null; // Skip invalid entries
    }
  
    const time = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  
    return { name: time, dataset1: entry.likes, dataset2: entry.views };
  }).filter(entry => entry !== null); // Filter out any null values from invalid dates

  useEffect(() => {
    const socket = new WebSocket(SOCKET_SERVER_URL);

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "new_data") {
        // Handle new incoming data
        setData(message.data);
      }
    };

    socket.onclose = () => {
      console.log("Disconnected from server");
    };

    return () => {
      socket.close();
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleStartCron = async () => {
   // const { startDate, startTime, endDate, endTime } = formData;
   try {
    

    const response = await fetch(`${API_SERVER_URL}/campaign/start-cron`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData ),
    });

    const result = await response.json();
    console.log("Response from server:", result);
    
    } catch (error) {
      console.error("Error starting cron:", error);
    }
  };

  const handleStopCron = async () => {
    try {
      const response = await fetch(`${API_SERVER_URL}/stop-cron`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      console.log("Cron stopped:", result.message);
    } catch (error) {
      console.error("Error stopping cron:", error);
    }
  };

  return (
    <>
      <div style={{ height: "100vh" }}>
        <h1>Real-Time Data from InfluxDB</h1>
        <form>
          <label>
            Start Date:
            <input
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Start Time:
            <input
              type="time"
              name="startTime"
              value={formData.startTime}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            End Date:
            <input
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            End Time:
            <input
              type="time"
              name="endTime"
              value={formData.endTime}
              onChange={handleInputChange}
              required
            />
          </label>
          <button type="button" onClick={handleStartCron}>
            Start Cron
          </button>
          <button type="button" onClick={handleStopCron}>
            Stop Cron
          </button>
        </form>
       


        <h2 >Recharts Line Chart</h2>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={formattedData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />

          {/* Area for Likes */}
          <Area
            type="monotone"
            dataKey="dataset1"
            name="Likes"
            stroke="#EA0063"
            fill="#EA0063"
            opacity={0.3}
          />

          {/* Area for Views */}
          <Area
            type="monotone"
            dataKey="dataset2"
            name="Views"
            stroke="#8884d8"
            fill="#8884d8"
            opacity={0.3}
          />
        </AreaChart>
      </ResponsiveContainer>
      </div>
    </>
  );
};

export default InfluxDB;
