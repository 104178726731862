import React, { useState, useEffect } from "react";
import styles from "./SignupForm.module.css";
import { Link, useNavigate } from "react-router-dom";
import formImage from "../../assets/formImage.png";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { auth, facebook } from "../../firebase-config";
import { signInWithPopup } from "firebase/auth";
import emailjs from "emailjs-com";
import google from "./image/google.png";
import facebook1 from "./image/facebook.png";
import inggage from '../../assets/inggage.png'

import "aos/dist/aos.css";


const SignupForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    displayName: "",
    profile_image: "",
    // otp: "",
    createdAt: "",
    isVerified: false,
  });
  const [user, setUser] = useState([]);
  // const [otpSent, setOtpSent] = useState(false);
  // const [generatedOtp, setGeneratedOtp] = useState("");
  const navigate = useNavigate();

  //Login with Google
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
    ux_mode: "popup",
    mode: "cors",
  });

  // to format time and date
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    // Get the date in 'YYYY-MM-DD' format (Indian locale)
    const formattedDate = date.toLocaleDateString("en-CA"); // 'en-CA' for ISO-like date format (YYYY-MM-DD)
    // Get the time in 'HH:MM' format (Indian Standard Time)
    const formattedTime = date.toLocaleTimeString("en-IN", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata", // Set timezone to IST
    });
    return `${formattedDate} ${formattedTime}`;
  }

  useEffect(() => {
    // Get current timestamp and format it
    const currentTimestamp = new Date().toISOString();
    const formattedCreatedAt = formatTimestamp(currentTimestamp);

    if (user && user.access_token) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          const updatedFormData = {
            email: res.data.email,
            displayName: res.data.name,
            password: "",
            profile_image: res.data.picture,
            isVerified: res.data.verified_email,
            createdAt: formattedCreatedAt,
          };
          setFormData(updatedFormData);
          localStorage.setItem("formData", JSON.stringify(updatedFormData));
          navigate("/Signupform");
        })
        .catch((err) => console.log(err));
    }
  }, [user, navigate]);

  // Login with Facebook
  const loginFace = async (provider) => {
    // Get current timestamp and format it
    const currentTimestamp = new Date().toISOString();
    const formattedCreatedAt = formatTimestamp(currentTimestamp);
    try {
      const result = await signInWithPopup(auth, provider);
      console.log(result.user);

      const updatedFormData = {
        email: result.user.email,
        displayname: result.user.displayName,
        password: "",
        isVerified: result.user.emailVerified,
        profile_image: result.user.photoURL,
        createdAt: formattedCreatedAt,
      };
      setFormData(updatedFormData);
      localStorage.setItem("formData", JSON.stringify(updatedFormData));
      navigate("/Signupform");
    } catch (e) {
      console.log(`login error ${e}`);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // handle OTP
  // const handleGetOtp = () => {
  //   if (!formData.email) {
  //     alert("Please enter your email");
  //     return;
  //   } // eslint-disable-next-line
  //   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
  //     const otp = Math.floor(100000 + Math.random() * 900000).toString();
  //     setGeneratedOtp(otp);

  //     const templateParams = {
  //       email: formData.email,
  //       message:
  //         "Use this otp to verify your email and get started with Inggage.",
  //       otp: otp,
  //     };

  //     emailjs
  //       .send(
  //         "service_rsbb72i", // Replace with your service ID
  //         "template_8ily7bb", // Replace with your template ID
  //         templateParams,
  //         "Rv2--lg-zY8PMp2HG" // Replace with your user ID
  //       )
  //       .then(
  //         (response) => {
  //           console.log(
  //             "OTP sent successfully",
  //             response.status,
  //             response.text
  //           );
  //           alert("OTP sent Successfully");
  //           setOtpSent(true);
  //         },
  //         (err) => {
  //           console.log("Failed to send OTP", err);
  //         }
  //       );
  //   } else {
  //     alert("Invalid Email. Please try again.");
  //   }
  // };

  // function for OTP verification
  // const handleVerifyOtp = () => {
  //   console.log(formData)
  //   if (formData.otp === generatedOtp) {
  //     alert("OTP verified successfully!");
  //     localStorage.setItem("formData", JSON.stringify(formData));
  //     navigate("/Signupform");
  //   } else {
  //     alert("Invalid OTP. Please try again.");
  //   }
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Get current timestamp and format it
    const currentTimestamp = new Date().toISOString();
    const formattedCreatedAt = formatTimestamp(currentTimestamp);

    formData.createdAt = formattedCreatedAt;

    if (!formData.email) {
      alert("Please enter your email");
    } else {
      if (
        // eslint-disable-next-line
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
      ) {
        localStorage.setItem("formData", JSON.stringify(formData));
        navigate("/Signupform");
      } else {
        alert("Invalid Email. Please try again.");
      }
    }
  };

  return (
    <>
      <div className={styles.SignupForm}>
        <div className={styles.formImage}>
          <img src={inggage} alt="formimage"></img>
        </div>
        <div className={styles.formContainer}>
          <form onSubmit={handleSubmit} className={styles.formBox}>
            <h2 className={styles.heading}>Sign Up</h2>
            <p className={styles.description}>
              We will require your email ID to further proceed and get in touch
              with our team
            </p>

            <input
              className={styles.inputField}
              type="text"
              name="displayName"
              value={formData.displayName}
              onChange={handleChange}
              placeholder="    Enter Your Name"
            />
            <input
              className={styles.inputField}
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="    Enter your Email ID"
            />
            <input
              className={styles.inputField}
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="    Set your password"
            />
            <button type="submit" className={styles.continueButton}>
              Continue
            </button>

            <div className={styles.orText}>OR</div>
            <div className={styles.buttonContainer}>
              <img
                src={google}
                type="submit"
                onClick={() => login()}
                alt="Google logo 24px"
              />
              <img
                src={facebook1}
                type="submit"
                onClick={() => loginFace(facebook)}
                alt="Facebook logo 24px"
              />
            </div>
            <div className={styles.NavContainer}>
              <Link to="/login" className={styles.NavSignup}>
                <p>SignIn</p>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignupForm;

{
  /**
  {!otpSent ? (
              <>
                <input
                  className={styles.inputField}
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="    Enter your Email ID"
                />
                <input
                  className={styles.inputField}
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="    Set your password"
                />
                <button
                  type="button"
                  onClick={handleGetOtp}
                  className={styles.continueButton}
                >
                  Get OTP
                </button>
              </>
            ) : (
              <>
                <input
                  className={styles.inputField}
                  type="text"
                  name="otp"
                  value={formData.otp}
                  onChange={handleChange}
                  placeholder="Enter your OTP"
                />
                <button
                  type="button"
                  onClick={handleVerifyOtp}
                  className={styles.continueButton}
                >
                  Verify OTP
                </button>
              </>
            )}

  
  */
}
