// src/components/LineChart/LineChart.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import baseUrl from "../../../api/universalApi";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styles from "./LineChart.module.css";

const SOCKET_SERVER_URL =
  "wss://inggage-backend-27064908213.us-central1.run.app/ws";

const CustomLineChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const socket = new WebSocket(SOCKET_SERVER_URL);

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "new_data" && message.data) {
        // Accumulate data and limit to the latest 50 entries
        setData(message.data);
      }
    };

    socket.onclose = () => {
      console.log("Disconnected from server");
    };

    return () => {
      socket.close();
    };
  }, []);

  const handleRefresh = async () => {
    try {
      const storedData = localStorage.getItem("submissionData");
      if (storedData) {
        // Parse the JSON string back to an object
        const parsedData = JSON.parse(storedData);
        const response = await axios.post(
          `${baseUrl}/campaign/campaign-refresh`,
          {
            campaignName: parsedData.campaignName,
          }
        );
        //  console.log('Data fetched successfully:', response.data);
        setData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Format data to extract time and rename keys for chart display
  const formattedData = data
    .map((entry) => {
      // Parse the date and validate
      const date = new Date(entry.timestamp);
      if (isNaN(date)) {
        console.warn(`Invalid timestamp detected: ${entry.timestamp}`);
        return null; // Skip invalid entries
      }

      const time = date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      return { name: time, dataset1: entry.likes, dataset2: entry.views };
    })
    .filter((entry) => entry !== null); // Filter out any null values from invalid dates

  return (
    <div className={styles.chartContainer}>
      <h2 className={styles.title}>Recharts Line Chart </h2>
      <span className=" btn btn-primary" onClick={handleRefresh}>
        Refresh
      </span>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={formattedData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />

          {/* Area for Likes */}
          <Area
            type="monotone"
            dataKey="dataset1"
            name="Likes"
            stroke="#EA0063"
            fill="#EA0063"
            opacity={0.3}
          />

          {/* Area for Views */}
          <Area
            type="monotone"
            dataKey="dataset2"
            name="Views"
            stroke="#8884d8"
            fill="#8884d8"
            opacity={0.3}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomLineChart;
