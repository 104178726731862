import React from "react";
import styles from "./CampaignInfo.module.css";
import avatar from "../../../assets/images/avatar.png";

const CampaignInfo = () => {
  const campaigns = [
    {
      id: 1,
      title: "HarGharTiranga",
      brand: "Nike",
      views: "90K",
      performance: "3%",
      ebr: "96.5%",
    },
    {
      id: 2,
      title: "HarGharTiranga",
      brand: "Nike",
      views: "90K",
      performance: "3%",
      ebr: "96.5%",
    },
  
  ];

  const reviews = [
    {
      id: 1,
      name: "Suryansu Dash",
      brand: "Nike",
      review:
        "the examining or considering again of something in order to decide if changes are necessary",
      rating: 5.0,
      avatar: "https://via.placeholder.com/50",
    },
    {
      id: 2,
      name: "Suryansu Dash",
      brand: "Nike",
      review:
        "the examining or considering again of something in order to decide if changes are necessary",
      rating: 5.0,
      avatar: "https://via.placeholder.com/50",
    },
  ];

  return (
    <>
      <div className={styles.campaignContainer}>
        {campaigns.map((campaign) => (
          <div key={campaign.id} className={styles.card}>
            <div className={styles.cardInfo}>
              <img
                src="https://hoffmannmurtaugh.com/wp-content/uploads/2022/02/basics-influencer-marketing-1920x1000-1-1.png"
                alt={campaign.title}
                className={styles.cardImage}
              />
              <div className={styles.brandInfo}>
                <img
                  src={avatar}
                  alt={campaign.brand}
                  className={styles.cardBrandImage}
                />
                <div>
                  <h3 className={styles.cardTitle}>{campaign.title}</h3>
                  <p className={styles.cardBrand}>{campaign.brand}</p>
                </div>
              </div>
              <div className={styles.cardStats}>
                <span className={styles.statItem}>
                  Views <br /> <strong>{campaign.views}</strong>
                </span>
                <span className={styles.statItem}>
                  Performance <br /> <strong>{campaign.performance}</strong>
                </span>
                <span className={styles.statItem}>
                  EBR <br /> <strong>{campaign.ebr}</strong>
                </span>
              </div>
              <button className={styles.analyticsButton}>View Analytics</button>
            </div>
          </div>
        ))}
      </div>
      {/*   Review secction */}
      
      <div className={styles.campaignReviews}>
      <h2 className={styles.reviewsTitle}>Reviews</h2>
        <div className={styles.reviewsContainer}>
          {reviews.map((review) => (
            <div key={review.id} className={styles.reviewCard}>
              <div className={styles.reviewHeader}>
                <img src={avatar} alt={review.name} className={styles.avatar} />
                <div>
                  <h3 className={styles.reviewName}>{review.name}</h3>
                  <p className={styles.reviewBrand}>{review.brand}</p>
                </div>
              </div>
              <p className={styles.reviewText}>{review.review}</p>
              <div className={styles.reviewFooter}>
                <span className={styles.star}>⭐</span>
                <span>{review.rating}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CampaignInfo;
