import React from 'react'
import styles from './Newsletter.module.css'

const Newsletter = () => {
  return (
    <>
     {/* Newsletter-section */}
     <div className={styles.newslettersection}>
        <h2>Join Our Newsletter</h2>
        <p>
          Discover the power of data-driven decision-making with our Pay As You Go SaaS Platform.
        </p>

        <form className={styles.newsletterform}>
          <input type="email" placeholder="Enter your email" required />
          <button type="submit">Subscribe</button>
        </form>
      </div>
    </>
  )
}

export default Newsletter