import React from "react";
import styles from "./About.module.css";
import { PiBrainFill } from "react-icons/pi";
import { RiFolderReceivedFill } from "react-icons/ri";
import { FaUsers } from "react-icons/fa6";
import { FaUserPlus } from "react-icons/fa6";
import { FaScroll } from "react-icons/fa6";
import { RiHomeWifiFill } from "react-icons/ri";

const About = () => {
  return (
    <div className={styles["about-section"]}>
      <div className={styles["info-box"]}>
        <div className={styles["info-item"]}>
          <div className={styles.icon}>
            <div>
              {" "}
              <PiBrainFill className={styles["icon-image"]} />
            </div>
          </div>
          <div className={styles["info-content"]}>
            <h3>Creator Engagement</h3>
            <p>
              Earn up to 2x your base rate and receive timely payments. Our
              platform provides transparency, flexibility, data-driven insights,
              dedicated support, and opportunities for community and career
              growth.
            </p>
          </div>
        </div>
        <div className={styles["info-item"]}>
          <div className={styles.icon}>
            <div>
              {" "}
              <RiFolderReceivedFill className={styles["icon-image"]} />
            </div>
          </div>
          <div className={styles["info-content"]}>
            <h3>Brand Profit</h3>
            <p>
              Pay only for the reach you generate, eliminate overspending, and
              access data-driven insights to optimize your campaigns. Our
              platform streamlines the process, ensuring scalability and
              measurable results.
            </p>
          </div>
        </div>
      </div>

      <div className={styles["stats-box"]}>
        <div className={styles["stats-box-inner"]}>
          <div className={styles["stat-item"]}>
            <h3>
              2,719,432+ <FaUsers className={styles["icon-image"]} />
            </h3>
            <p>Followers</p>
            <span>🔼 10.2 +1.01% this week</span>
          </div>
          <div className={styles["stat-item"]}>
            <h3>
              71,800K <FaUserPlus className={styles["icon-image"]} />
            </h3>
            <p>Total Users</p>
            <span>🔼 10.2 +1.01% this week</span>
          </div>
          <div className={styles["stat-item"]}>
            <h3>
              58,9216+ <FaScroll className={styles["icon-image"]} />
            </h3>
            <p>Total Visitor</p>
            <span>🔽 10.2 +1.01% this week</span>
          </div>
          <div className={styles["stat-item"]}>
            <h3>
              100,403K <RiHomeWifiFill className={styles["icon-image"]} />
            </h3>
            <p>Total Sold Product</p>
            <span>🔼 10.2 +1.01% this week</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
