import React, { useState } from 'react';
import axios from 'axios';

const Admin = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [profileImage, setProfileImage] = useState(''); // State to store the profile image URL

  const handleApiCall = async () => {
    setLoading(true);
    try {
      // Data to send to the backend
      const data = {
        action: 'trigger',
        timestamp: new Date().toISOString(),
        profile_image: profileImage, // Include profile_image URL in the body
      };

      const res = await axios.post('https://inggage-backend-27064908213.us-central1.run.app/admin/user', data); // POST request with data
      setResponse(res.data); // Store response in state
      console.log(res.data); // Log the response
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h1>Admin Panel</h1>

      {/* Input to update profile image */}
      <div className="form-group mt-3">
        <label htmlFor="profileImage">Profile Image URL</label>
        <input
          type="text"
          className="form-control"
          id="profileImage"
          value={profileImage}
          onChange={(e) => setProfileImage(e.target.value)}
          placeholder="Enter profile image URL"
        />
      </div>

      {/* Button to trigger the API call */}
      <button
        className="btn btn-primary mt-3"
        onClick={handleApiCall}
        disabled={loading}
      >
        {loading ? 'Loading...' : 'Trigger API'}
      </button>

      {response && (
        <div className="alert alert-success mt-3" role="alert">
          API Response: {response.message}
        </div>
      )}
    </div>
  );
};

export default Admin;
