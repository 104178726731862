import React, { useState, useEffect } from "react";
import styles from "./ProfileInfo.module.css";
import { useNavigate } from "react-router-dom";
import { DeleteUser, getInsta } from "../../../actions/AuthActions";
import { useDispatch } from "react-redux";
import { FaSyncAlt, FaInstagram } from "react-icons/fa";

const ProfileInfo = ({ profileData , isBrand}) => {
  //const date = new Date(profileData.createdAt);
  const [facebookUserAccessToken, setFacebookUserAccessToken] = useState("");
  const [instaid, setInstaId] = useState(null);

  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log("data", profileData)
  const id = profileData._id;
  // console.log(id)
  // Function to open the popup
  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleUserDelete = () => {
    try {
      dispatch(DeleteUser(id));
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  /* --------------------------------------------------------
   *              FACEBOOK LOGIN AND INITIAL SETUP
   * --------------------------------------------------------
   */

  // Check if access token is in localStorage when the component mounts
  useEffect(() => {
    const storedAccessToken = localStorage.getItem("FacebookUserAccessToken");
    const storedInstaId = localStorage.getItem("InstaId");

    // window.FB.getLoginStatus((response) => {
    //   if (response.status === "connected") {
    //     const accessToken = response.authResponse?.accessToken;
    //     setFacebookUserAccessToken(accessToken);
    //     localStorage.setItem("FacebookUserAccessToken", accessToken);
    //   }
    // });
    if (storedAccessToken) {
      setFacebookUserAccessToken(storedAccessToken);
    } else {
      window.FB.getLoginStatus((response) => {
        if (response.status === "connected") {
          const accessToken = response.authResponse?.accessToken;
          setFacebookUserAccessToken(accessToken);

          
        }
      });
    }

    if (storedInstaId) {
      setInstaId(storedInstaId);
    }

    // Trigger the chain of function calls if the user is already logged in
  }, [facebookUserAccessToken]);

  const logInToFB = async () => {
    window.FB.login(
      (response) => {
        const accessToken = response.authResponse?.accessToken;
        if (accessToken) {
          setFacebookUserAccessToken(accessToken);
          localStorage.setItem("FacebookUserAccessToken", accessToken);
        }
        passToken(accessToken);
      },
      {
        scope: "instagram_basic,pages_show_list",
      }
    );
  };

  const passToken = async (token) => {
    const userId = profileData._id;

    try {
      dispatch(getInsta({ token, userId }));
    } catch (error) {
      console.error("Request failed:", error);
    }
  };

  const handleConnect = () => {
    logInToFB();
  };

  const handleOpenInstagram = () => {
    const url = `https://instagram.com/${profileData.instaUsername}`;
    window.open(url, '_blank');
  };

  return (
    <div className={styles.descriptionBox}>
      <div className={styles.section}>
        <h4
          onClick={!profileData.instaId ? handleOpenPopup : undefined} // Only assign onClick if not connected
          className={profileData.instaId ? styles.nonClickable : styles.clickable}
        >
          {profileData.instaId ? (
            <>
            <div className="d-flex flex-row">
            <div style={{cursor:"pointer"}} onClick={handleOpenInstagram}>
              <FaInstagram
                style={{
                  color:"red",
                  marginRight: "5px",
                  width: "20px",
                  cursor:"pointer"
                }}
              />
              
              {profileData.instaUsername} 
             
            </div>
            <div>
              {!isBrand && (
                <FaSyncAlt
                onClick={handleConnect} 
                style={{
                  marginLeft: "15px",
                  cursor: "pointer",
                  width: "15px",
                  verticalAlign: "middle",
                }} 
              />
              )}
            
              </div>
              </div>
            </>
          ) : (
            <>
             <div>
              <div>Instagram</div>
              <div>
                <button className="btn btn-primary px-2 py-1 rounded-5">Connect</button>
              </div>
             </div>
            </>
           
          )}

          
          {profileData.instaId ? (
            <>
              <p>Followers: {profileData.followers}</p>
              <p>Following: {profileData.following}</p>
            </>
          ) : (
           <></>
          )}
        </h4>

        <hr />

        {/* Popup Component */}
        {showPopup && (
          <div className={styles.popupOverlay}>
            <div className={styles.popupContent}>
              <button className={styles.closeButton} onClick={handleClosePopup}>
                &times; {/* Cross symbol for closing */}
              </button>
              <h3>Instagram Connection</h3>
              <div className="instructions">
                <p>
                  To access Instagram data, please connect your account by
                  logging in with Instagram.
                </p>
                <div className="steps">
                  <h3>Follow these steps:</h3>
                  <ol>
                    <li>Open Instagram App</li>
                    <li>Go to settings</li>
                    <li>
                      Convert your account to an Instagram professional account.
                    </li>
                    <li>Open Edit Profile</li>
                    <li>
                      Add a Facebook Page to your Instagram.
                      <ul>
                        <li>
                          You need to be the admin of the page to connect. Check
                          your Page's settings and make sure you have the
                          required permissions.
                        </li>
                      </ul>
                    </li>
                    <li>Click on Connect in the Inggage App</li>
                    <li>
                      Facebook Connect will open (Instagram requires Facebook
                      connect)
                    </li>
                    <li>
                      If connecting for the first time, tap on{" "}
                      <strong>Continue as `your name`</strong>.
                    </li>
                    <li>
                      If not connecting for the first time, tap on{" "}
                      <strong>Edit Settings</strong>.
                      <ul>
                        <li>5.1 Select your Instagram Account, tap Next</li>
                        <li>
                          5.2 Select your Facebook page which is linked with the
                          Instagram Professional Account, tap Next
                        </li>
                        <li>
                          5.3 Turn on toggles for all the permissions, tap Done
                        </li>
                      </ul>
                    </li>
                    <li>Instagram should be connected now</li>
                  </ol>
                </div>
              </div>
              <button className="btn btn-primary  rounded-5"  onClick={handleConnect}>connect</button>
            </div>
          </div>
        )}
      </div>

      <div className={styles.section}>
        <h3>Description</h3>
        {profileData.bio ? (
          <p>{profileData.bio}</p>
        ) : (
          <p style={{ cursor: "pointer", color: "blue" }}>Update Profile</p>
        )}

        <p className={styles.highlightedText}>I’m also a Top Pick Influencer</p>
      </div>
      <hr />

      <div className={styles.section}>
        <h3>Languages</h3>
        {profileData?.languages && profileData.languages.length > 0 ? (
          <ul>
            {profileData.languages.map((language, index) => (
              <li key={index}>{language}</li>
            ))}
          </ul>
        ) : (
          <p>English</p>
        )}
      </div>
      <hr />

      <div className={styles.section}>
        <h3>Socials</h3>
        <div className={styles.SocialIcon}>
          <p>Instagram</p>
          <p>Facebook</p>
          <p>YouTube</p>
        </div>
      </div>
      <hr />

      <div className={styles.section}>
        <h3>Genre</h3>
        <div className={styles.genreTags}>
          {profileData.interests && profileData.interests.length > 0 ? (
            profileData.interests.map((interest, index) => (
              <span key={index}>{interest}</span>
            ))
          ) : (
            <span style={{ cursor: "pointer", color: "blue", border:"none", padding:"0px" }}>
              Update Profile
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
