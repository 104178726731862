import React, { useEffect, useState } from "react";
import axios from "axios";

const Insta2 = () => {
  // States to store input, access token, insta ID, and media data
  const [accessToken, setAccessToken] = useState("");
  const [instaUserId, setInstaUserId] = useState("");
  const [postUrl, setPostUrl] = useState("");
  const [mediaData, setMediaData] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const storedAccessToken = localStorage.getItem("FacebookUserAccessToken");
    const storedInstaId = localStorage.getItem("InstaId");
    setAccessToken(storedAccessToken);
    setInstaUserId(storedInstaId);
  },[accessToken])

  // Function to fetch all Instagram media for the authenticated user
  const fetchInstaMedia = async () => {
    try {
      const mediaResponse = await axios.get(
        `https://graph.facebook.com/${instaUserId}/media`,
        {
          params: {
            fields: "id,caption,media_url,permalink,like_count,comments_count",
            access_token: accessToken,
          },
        }
      );

      const mediaItems = mediaResponse.data.data;
      console.log("All media items:", mediaItems);

      // Check if the input post URL matches any of the permalinks
      const matchedMedia = mediaItems.find((media, index) => {
        if (media.permalink === postUrl) {
          media.index = index; // Add index property to matched media
          return true;
        }
        return false;
      });

      if (matchedMedia) {
        // If found, set the matched media data
        setMediaData(matchedMedia);
        setError(""); // Clear any error
      } else {
        setError("Media not found.");
        setMediaData(null); // Clear any previous media data
      }
    } catch (err) {
      console.error("Error fetching media data:", err);
      setError("Failed to fetch Instagram media.");
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (postUrl.trim() === "") {
      setError("Please enter a valid post URL.");
      return;
    }

    // Fetch the media and match the permalink with the input URL
    fetchInstaMedia();
  };

  return (
    <div>
      <h1>Instagram Media Finder</h1>

      <div>
        <label>Access Token:</label>
        <input
          type="text"
          value={accessToken}
          onChange={(e) => setAccessToken(e.target.value)}
          placeholder="Enter Facebook user access token"
        />
      </div>

      <div>
        <label>Instagram User ID:</label>
        <input
          type="text"
          value={instaUserId}
          onChange={(e) => setInstaUserId(e.target.value)}
          placeholder="Enter Instagram user ID"
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div>
          <label>Post URL:</label>
          <input
            type="text"
            value={postUrl}
            onChange={(e) => setPostUrl(e.target.value)}
            placeholder="Enter Instagram post URL"
          />
        </div>

        <button type="submit" className="btn btn-primary">Find Media</button>
      </form>

      {error && <p style={{ color: "red" }}>{error}</p>}

      {mediaData && (
        <div>
          <h2>Media Found</h2>
          <p><strong>ID:</strong> {mediaData.id}</p>
          <p><strong>Caption:</strong> {mediaData.caption}</p>
          <p><strong>Media URL:</strong> <a href={mediaData.media_url} target="_blank" rel="noreferrer">{mediaData.media_url}</a></p>
          <p><strong>Permalink:</strong> <a href={mediaData.permalink} target="_blank" rel="noreferrer">{mediaData.permalink}</a></p>
          <p><strong>Likes:</strong> {mediaData.like_count}</p>
          <p><strong>Comments:</strong> {mediaData.comments_count}</p>
          <p><strong>Index:</strong> {mediaData.index}</p>
        </div>
      )}
    </div>
  );
};

export default Insta2;

