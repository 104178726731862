import React from "react";
import styles from "./CampaignPerformance.module.css";

const CampaignPerformance = () => {
  return (
    <div className={styles.campaignContainer}>
      <div className={styles.campaignViews}>
        <p>Total Reel Views</p>
        <h2>16K</h2>
        <hr />
        <p>Total Youtube Views</p>
        <h2>16K</h2>
      </div>
      <div className={styles.campaignSocial}>
        <div className={styles.campaignInstaYT}>
          <div className={styles.SocialIcon}>😁</div>
          <div className={styles.SocialData}>
            <p>Instagram</p>
            <p>245 followers</p>
          </div>
        </div>
        <div className={styles.campaignInstaYT}>
          <div className={styles.SocialIcon}>😁</div>
          <div className={styles.SocialData}>
            <p>Youtube</p>
            <p>245 Subscribers</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignPerformance;
