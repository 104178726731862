import React from "react";
import styles from "./Overview.module.css";
import CustomLineChart from './LineChart';
import CampaignPerformance from "./CampaignPerformance";

const Overview = () => {
  
  return (
    <>
      <div className={styles.overviewContainer}>
        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Spent till now</p>
            
            <h3 className={styles.redText}>₹7332.57</h3>
            <p className={styles.subText}>+20.1% from last month</p>
          </div>
          <div className={styles.icon}>💵</div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Maximum Views from</p>
            <h3 className={styles.blackText}>Mumbai</h3>
            <p className={styles.subText}>70% of your audience is from Mumbai</p>
          </div>
          <div className={styles.icon}>🥰</div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Total Accumulated Views</p>
            <h3 className={styles.blackText}>+12,234%</h3>
            <p className={styles.subText}>+19% from last month</p>
          </div>
          <div className={styles.icon}>🗓️</div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Average CPV</p>
            <h3 className={styles.blackText}>+573</h3>
            <p className={styles.subText}>+23% from last campaign</p>
          </div>
          <div className={styles.icon}>🔂</div>
        </div>
      </div>
      <div className={styles.ChartNPerform}>
        <div className={styles.chartContainer}>{ <CustomLineChart />}</div>
        <div className={styles.performanceContainer}>
          <CampaignPerformance />
        </div>
      </div>
    </>
  );
};

export default Overview;
