import React, { useState } from "react";

import styles from "./BrandProfile.module.css";
import { useSelector } from "react-redux";
import Overview from "./Overview/Overview";

import Requests from "./Requests/Requests";
import CampaignColl from "./CampaignCollection/CampaignColl";
import { useNavigate } from "react-router-dom";

const BrandProfile = ({requestedCreator}) => {
 
 const navigate = useNavigate();
  const authData = useSelector((state) => state.auth.authData.user);

  // collect all influencer Data.
  //get blogs
  const [activeTab, setActiveTab] = useState("overview");

  const renderContent = () => {
    switch (activeTab) {
      case "overview":
        return <Overview />;
     
      case "requests":
        return <Requests requestedCreator={requestedCreator} />;
      case "collection":
        return <CampaignColl />;

      default:
        return <Overview />;
    }
  };

  const handleCampaign = ()=>{
    navigate("/createcampaign")
  }

  return (
    <>
      <div className={styles.dashboardContainer}>
        <header className={styles.dashboardHeader}>
          <h1>Welcome {authData.displayname},</h1>
          <div className={styles.dateDownload}>
          <button className={styles.btnColor} onClick={handleCampaign}>Create Campaign</button>
            <div className={styles.dateRange}>
              <span>13 June 2023 - 14 July 2023</span>
            </div>
            <button className={styles.downloadButton}>Download</button>
          </div>
        </header>
        <div className={styles.dashboardNav}>
          <div className={styles.buttonContainer}>
            <button
              className={`${styles.navItem} ${
                activeTab === "overview" ? styles.active : ""
              }`}
              onClick={() => setActiveTab("overview")}
            >
              Overview
            </button>
           
            <button
              className={`${styles.navItem} ${
                activeTab === "requests" ? styles.active : ""
              }`}
              onClick={() => setActiveTab("requests")}
            >
              Requests
            </button>
            <button
              className={`${styles.navItem} ${
                activeTab === "collection" ? styles.active : ""
              }`}
              onClick={() => setActiveTab("collection")}
            >
              Collection
            </button>
          </div>
        </div>
        <div className={styles.contentContainer}>{renderContent()}</div>
      </div>
    </>
  );
};

export default BrandProfile;
