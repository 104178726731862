import React from "react";
import styles from "./Overview.module.css";
import CustomLineChart from './LineChart';
import CampaignPerformance from "./CampaignPerformance";

const Overview = () => {
  return (
    <>
      <div className={styles.overviewContainer}>
        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Spent till now</p>
            
            <h3 className={styles.redText}>₹7332.57</h3>
            <p className={styles.subText}>saving ₹1067.21</p>
          </div>
          <div className={styles.icon}>💵</div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Views</p>
            <h3 className={styles.blackText}>34,917</h3>
            <p className={styles.subText}>40K Expected Views</p>
          </div>
          <div className={styles.icon}>🥰</div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Remaining</p>
            <h3 className={styles.blackText}>1 Day</h3>
            <p className={styles.subText}>out of 14 days of the campaign</p>
          </div>
          <div className={styles.icon}>🗓️</div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>CPV</p>
            <h3 className={styles.blackText}>₹0.21</h3>
            <p className={styles.subText}>+0.01 since your last host</p>
          </div>
          <div className={styles.icon}>🔂</div>
        </div>
      </div>
      <div className={styles.ChartNPerform}>
        <div className={styles.chartContainer}>{ <CustomLineChart />}</div>
        <div className={styles.performanceContainer}>
          <CampaignPerformance />
        </div>
      </div>
    </>
  );
};

export default Overview;
