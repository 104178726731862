/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";

import logo from "../../assets/images/Logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//import toast from "react-hot-toast";
import { logout } from "../../actions/AuthActions";
import toast, { Toaster } from "react-hot-toast";
import { FaUserCircle } from "react-icons/fa";
import { GetRequestedList } from "../../actions/BrandActions.js";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const isLogin = useSelector((state) => state.auth.isLogin);
  const authData = useSelector((state) => state.auth.authData);
  const [isBrand, setIsBrand] = useState(false);



  useEffect(() => {
    if (authData) {
      const Brand = authData.user.userType === "Brand";
      setIsBrand(Brand);
      const id = authData.user._id;

      
    }
  }, [authData, dispatch]);

  //console.log(authData.userType)

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleProfile = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsNavVisible(!isNavVisible);
    if (authData) {
      if (isBrand) {
        const id = authData.user._id;
        // console.log(id);
        navigate(`/Brand/${id}`);
      } else {
        const id = authData.user._id;
        navigate(`/influencer/${id}`);
      }
    }
  };

  const handleClick = () => {
    setIsNavVisible(!isNavVisible);
    navigate("/Signup");
  };

  const handleLogout = () => {
    setIsNavVisible(!isNavVisible);
    try {
      dispatch(logout(navigate));
      toast.success("Logged out!!", {
        duration: 4000,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Toaster />
      <header className={styles.headerContainer}>
        <div className={styles.headerContent}>
          <div>
            <a href="/">
              <img src={logo} alt="Logo" className={styles.logo} />
            </a>
          </div>
          <div className={styles.menuIcon} onClick={toggleNav}>
            <input
              type="checkbox"
              role="button"
              aria-label="Display the menu"
              className={styles.menu}
              checked={isNavVisible}
              onChange={toggleNav}
            />
          </div>
          <nav
            className={`${styles.navigation} ${
              isNavVisible ? styles.showNav : ""
            }`}
          >
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? styles.active : styles.navLink
              }
              onClick={toggleNav}
            >
              Home
            </NavLink>
            <NavLink
              to="/creator"
              className={({ isActive }) =>
                isActive ? styles.active : styles.navLink
              }
              onClick={toggleNav}
            >
              Creator
            </NavLink>
            <NavLink
              to="/brand"
              className={({ isActive }) =>
                isActive ? styles.active : styles.navLink
              }
              onClick={toggleNav}
            >
              Brand
            </NavLink>
            <NavLink
              to="/talent"
              className={({ isActive }) =>
                isActive ? styles.active : styles.navLink
              }
              onClick={toggleNav}
            >
              Talent
            </NavLink>

            {/* If not Logged In */}
            {isLogin ? (
              <div className={styles.dropdownContainer}>
                <FaUserCircle
                  onClick={toggleDropdown}
                  className={styles.userProfileButton}
                />
                {isDropdownOpen && (
                  <div className={styles.dropdownMenu}>
                    <button
                      onClick={handleProfile}
                      className={styles.dropdownItem}
                    >
                      Profile
                    </button>
                    <button
                      onClick={handleLogout}
                      className={styles.dropdownItem}
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div>
                  <button
                    className={styles.GetinTouch}
                    onClick={() => {
                      navigate("/Contact");
                    }}
                  >
                    GET IN TOUCH
                  </button>
                </div>
                <div>
                  <button
                    onClick={handleClick}
                    className={styles.signFormButton}
                  >
                    SIGNUP
                  </button>
                </div>
              </>
            )}
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
