/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import styles from "./CreateCampaign.module.css";
import axios from "axios";
import baseUrl from "../../api/universalApi";
import { useNavigate } from "react-router-dom";
//import toast from "react-hot-toast";

const CreateCampaign = ({ creatorss, brandID }) => {
  const [formData, setFormData] = useState({
    campaignName: "",
    campaignLogo: null,
    campaignDescription: "",
    campaignGoal: "",
    customGoal: "",
    platform: "",

    startDate: "",
    endDate: "",
    hashTags: [],

    influencerID: "",
    influencerName: "",
    accessToken: "",
    instagramID: "",

    estimatedReach: "",
    totalBudget: "",
    reelLink: "",
  });

  const [filteredInfluencers, setFilteredInfluencers] = useState([]);
  const [selectedInfluencersDetails, setSelectedInfluencersDetails] =
    useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [hashTags, setHashtags] = useState([]);
  const [datapoints, setDatapoints] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  // Sample influencers data - replace this with your actual data source or API call
  const influencers = creatorss;
  // Add more as needed
  //console.log(creatorss)

  const handleKeyDown = (e) => {
    if (e.key === " " && inputValue.trim() !== "") {
      // Prevent default space behavior and add keyword
      e.preventDefault();
      addhashtags(inputValue.trim());
    }
  };

  const addhashtags = (hashtag) => {
    if (!hashTags.includes(hashtag)) {
      setHashtags([...hashTags, hashtag]);
      setFormData({ ...formData, hashTags: hashTags });
      setInputValue(""); // Clear input after adding
    }
  };

  const removeKeyword = (hashtag) => {
    setHashtags(hashTags.filter((k) => k !== hashtag));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handlehashTagChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setInputValue(value);
    setFormData({ ...formData, totalBudget: value });
  };

  const handleGoalChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, campaignGoal: value, customGoal: "" });
  };

  const handleSelectInfluencer = (influencer) => {
    // Update the form data to reflect the selected influencer's name
    console.log(influencer.influencerId);
    setFormData({
      ...formData,
      influencerName: influencer.displayname,
      accessToken: influencer.longLivedAccess,
      instagramID: influencer.instaId,
      influencerID: influencer.influencerId,
    });

    // Set the details of the selected influencer (optional, for further use)
    setSelectedInfluencersDetails(influencer);

    // Clear the filtered list after selecting an influencer
    setFilteredInfluencers([]);
  };

  const handleInfluencerInput = (e) => {
    const input = e.target.value;

    // Update the input field with the current user input
    setFormData({ ...formData, influencerName: input });

    // Filter the influencers based on input
    if (input) {
      const filtered = influencers.filter((influencer) =>
        influencer.displayname.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredInfluencers(filtered);
    } else {
      setFilteredInfluencers([]);
    }
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      //const fileName = Date.now() + file.name;

      // Update selected image and form data
      setSelectedImage(file); // Keep file in state if needed elsewhere
      setFormData({ ...formData, campaignLogo: file }); // Store as a file object
    }
  };

  const prepareFormDataForSubmission = () => {
    const submissionData = new FormData();

    // Append each field from formData to submissionData
    Object.keys(formData).forEach((key) => {
      if (key === "campaignLogo") {
        // Append the file directly
        submissionData.append("campaignImg", formData[key]); // Match server's expected key
      } else {
        // Append other fields
        submissionData.append(key, formData[key]);
      }
    });

    return submissionData;
  };

  function convertFormDataToObject(formData) {
    const obj = {};
    formData.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const id = brandID;
      const submissionData = prepareFormDataForSubmission();

      const submissionDataObject = convertFormDataToObject(submissionData);
     // console.log(submissionDataObject);
      localStorage.setItem('submissionData', JSON.stringify(submissionDataObject));

      const response = await fetch(`${baseUrl}/campaign/createcampaign/${id}`, {
        method: "POST",
        body: submissionData,
      });

      const result = await response.json();
      console.log(result);
      navigate(`/Brand/${id}`)
    } catch (error) {
      console.error("Error submitting campaign data:", error);
    }
  };

  return (
    <div className={styles.createCampaignContainer}>
      <div className={styles.formContainer}>
        <h2>Create Campaign</h2>
        <form onSubmit={handleFormSubmit} className={styles.form}>
          {/* Campaign Name */}
          <div className={styles.leftForm}>
            <div className={styles.formGroup}>
              <label>Campaign Name:</label>
              <input
                type="text"
                name="campaignName"
                value={formData.campaignName}
                onChange={handleInputChange}
                required
              />
            </div>

            {/* Campaign Description */}
            <div className={styles.formGroup}>
              <label htmlFor="campaign">Campaign Description</label>
              <textarea
                type="text"
                name="campaignDescription"
                value={formData.campaignDescription}
                onChange={handleInputChange}
                className="w-100 border-0"
              />
            </div>

            {/* Campaign Logo */}
            <div className={styles.formGroup}>
              <label htmlFor="campaign">Campaign Logo</label>
              <input
                type="file"
                id="imageUpload"
                accept="image/*"
                onChange={handleImageUpload}
              />
            </div>

            {/* Campaign Goal */}
            <div className={styles.formGroup}>
              <label>Campaign Goal:</label>
              <select
                name="campaignGoal"
                value={formData.campaignGoal}
                onChange={handleGoalChange}
                required
              >
                <option value="">Select a goal</option>
                <option value="Brand/product awareness">
                  Brand/product awareness
                </option>
                <option value="maximize app installs">
                  Maximize app installs
                </option>
                <option value="increase sales/conversions">
                  Increase sales/conversions
                </option>
                <option value="others">Others, specify please</option>
              </select>
            </div>

            {/* Custom Goal Input */}
            {formData.campaignGoal === "others" && (
              <div className={styles.formGroup}>
                <label>Specify Goal:</label>
                <input
                  type="text"
                  name="customGoal"
                  value={formData.customGoal}
                  onChange={handleInputChange}
                />
              </div>
            )}
          </div>
          <div className={styles.genderDiv}>
            {/* Platform */}
            <div className={styles.formGroup}>
              <label>Platform:</label>
              <select
                name="platform"
                value={formData.platform}
                onChange={handleInputChange}
                required
              >
                <option value="">Select platform</option>
                <option value="instagram">Instagram</option>
                <option value="facebook">Facebook</option>
                <option value="youtube">YouTube</option>
              </select>
            </div>
            {/* Start Date */}
            <div className={styles.formGroup}>
              <label>Start Date:</label>
              <input
                type="date"
                name="startDate"
                value={formData.startDate}
                onChange={handleInputChange}
                required
              />
            </div>

            {/* End Date */}
            <div className={styles.formGroup}>
              <label>End Date:</label>
              <input
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={handleInputChange}
                required
              />
            </div>

            <div>
              <label className="block ">Hashtags</label>
              <div className={styles.hashTagsContainer}>
                <div className={styles.hashTags}>
                  {hashTags.map((hashtag, index) => (
                    <span key={index} className="flex">
                      {hashtag}
                      <button
                        type="button"
                        onClick={() => removeKeyword(hashtag)}
                      >
                        &times;
                      </button>
                    </span>
                  ))}
                </div>
                <textarea
                  type="text"
                  className="flex-1 outline-none p-0 text-black"
                  value={inputValue}
                  onChange={handlehashTagChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Type and press space..."
                />
              </div>
            </div>
          </div>

          <div className={styles.rightForm}>
            {/* Influencer Search */}
            <div className={styles.formGroup}>
              <label>Search Influencer:</label>
              <input
                type="text"
                name="influencerName"
                value={formData.influencerName}
                onChange={handleInfluencerInput}
                placeholder="Type influencer name"
              />
              {filteredInfluencers.length > 0 && (
                <ul className={styles.suggestionsList}>
                  {filteredInfluencers.map((influencer, index) => (
                    <li
                      key={index}
                      onClick={() => handleSelectInfluencer(influencer)}
                    >
                      {influencer.displayname}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Influencer Details */}
            {selectedInfluencersDetails && (
              <div className={styles.influencerDetails}>
                <p>Name:{selectedInfluencersDetails.displayname}</p>
                <p>Followers: {selectedInfluencersDetails.followers}</p>

                <p>Profile Views: {selectedInfluencersDetails.profileViews}</p>
              </div>
            )}

            {/* Deliverables */}
            <div className={styles.formGroup}>
              <label>Deliverables:</label>
              <select
                name="deliverables"
                value={formData.deliverables}
                onChange={handleInputChange}
                required
              >
                <option value="">Select deliverables</option>
                <option value="post">Post</option>
                <option value="reel">Reel</option>
                <option value="story">Story</option>
              </select>
            </div>

            <div className={styles.formGroup}>
              <label>Total Budget:</label>
              <input
                type="number"
                name="totalBudget"
                value={formData.totalBudget}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className={styles.LinkDiv}>
            {/* Estimated Reach */}
            <div className={styles.formGroup}>
              <label>Estimated Reach:</label>
              <input
                type="number"
                name="estimatedReach"
                value={formData.estimatedReach}
                onChange={handleInputChange}
                required
              />
            </div>

            {/* Reel Link */}
            <div className={styles.formGroup}>
              <label>Reel Link:</label>
              <input
                type="text"
                name="reelLink"
                value={formData.reelLink}
                onChange={handleInputChange}
                placeholder="Paste reel link"
              />
            </div>
            {/* Submit Button */}
            <button type="submit" className={styles.submitButton}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateCampaign;
