import * as getAPI from "../api/CreatorRequests";
const GET_CREATORS_REQUEST = 'GET_CREATORS_REQUEST';
const GET_CREATORS_SUCCESS = 'GET_CREATORS_SUCCESS';
const GET_CREATORS_FAILURE = 'GET_CREATORS_FAILURE';

export const GetAll_Creators = () => async (dispatch) => {
 
  dispatch({ type: GET_CREATORS_REQUEST }); 

  try {
    const { data } = await getAPI.getallCreators(); 
    //console.log(data)
    // Dispatch success action with the data received
    dispatch({
      type: GET_CREATORS_SUCCESS,
    });

    return data.users; // For debugging
  } catch (error) {
   
    dispatch({
      type: GET_CREATORS_FAILURE,
      payload: error.message, 
    });

    console.log(error); 
  }
  };
  
