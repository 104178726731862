import React from 'react';
import styles from './Campaign.module.css';

const Campaign = () => {
  return (
    <div className={styles['campaign-section']}>
      <div className={styles['campaign-content']}>
        <h2>Main bhi Chowkidaar Campaign</h2>
        <p>
          On 14 March 2019, Modi responded to the jibe of Chowkidar Chor Hai coined by Rahul Gandhi by
          launching a campaign with the slogan “Main Bhi Chowkidar” for his supporters, implying that
          everyone is a fighter against corruption and social evils.
        </p>
        <div className={styles['campaign-buttons']}>
          <button className={styles['read-more']}>Read More</button>
          <button className={styles['contact-us']}>Contact Us</button>
        </div>
      </div>
      <div className={styles['campaign-image']}>
        <img
          src="https://upload.wikimedia.org/wikipedia/en/thumb/e/ec/Main_bhi_chowkidar_logo.png/411px-Main_bhi_chowkidar_logo.png"
          alt="Campaign Image"
        />
      </div>
    </div>
  );
};

export default Campaign;
