const filteroptions = [
  { label: "All", value: "" }, // Add an "All" option to clear the filter
  { label: "Actor", value: "Actor" },

  { label: "Artist", value: "Artist" },
  { label: "Athlete", value: "Athlete" },
  { label: "Author", value: "Author" },
  { label: "Blogger", value: "Blogger" },
  { label: "Book Critic", value: "Book Critic" },
  { label: "Chef", value: "Chef" },
  { label: "Comedian", value: "Comedian" },
  { label: "Content Creator", value: "Content Creator" },
  { label: "Dancer", value: "Dancer" },
  { label: "DJ", value: "DJ" },
  { label: "Editor", value: "Editor" },
  { label: "Entrepreneur", value: "Entrepreneur" },
  { label: "Fashion Blogger", value: "Fashion Blogger" },
  { label: "Fashion Designer", value: "Fashion Designer" },
  { label: "Fashion Model", value: "Fashion Model" },
  { label: "Fashion Photographer", value: "Fashion Photographer" },
  { label: "Film Critic", value: "Film Critic" },
  { label: "Film Director", value: "Film Director" },
  { label: "Fitness Model", value: "Fitness Model" },
  { label: "Fitness Trainer", value: "Fitness Trainer" },
  { label: "Food Blogger", value: "Food Blogger" },
  { label: "Food Critic", value: "Food Critic" },
  {
    label: "Gadgets & Technology Critic",
    value: "Gadgets & Technology Critic",
  },
  { label: "Gamer", value: "Gamer" },
  { label: "Graphic/Web Designer", value: "Graphic/Web Designer" },
  { label: "Journalist", value: "Journalist" },
  { label: "Life Coach", value: "Life Coach" },
  { label: "Lifestyle", value: "Lifestyle" },
  { label: "Luxury Blogger", value: "Luxury Blogger" },
  { label: "Makeup/Hair Artist", value: "Makeup/Hair Artist" },
  {
    label: "Mental Well-being & Psychology",
    value: "Mental Well-being & Psychology",
  },
  { label: "Motivational Speaker", value: "Motivational Speaker" },
  { label: "Musician", value: "Musician" },
  { label: "News Personality", value: "News Personality" },
  { label: "Nutritionist", value: "Nutritionist" },
  { label: "Pets & Animals", value: "Pets & Animals" },
  { label: "Photographer", value: "Photographer" },
  { label: "Political Candidate", value: "Political Candidate" },
  { label: "Public Figure", value: "Public Figure" },
  { label: "Science & Philosophy", value: "Science & Philosophy" },
  { label: "Social Activist", value: "Social Activist" },
  { label: "Sports Enthusiast", value: "Sports Enthusiast" },
  { label: "Sports Person", value: "Sports Person" },
  { label: "Travel", value: "Travel" },
  { label: "Travel Blogger", value: "Travel Blogger" },
  { label: "Travel Photographer", value: "Travel Photographer" },
  { label: "Travel Vlogger", value: "Travel Vlogger" },
  { label: "Write", value: "Write" },
];

export default filteroptions;
