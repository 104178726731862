import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styles from "./Scroller.module.css";

function Scroller() {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // Set z-index for panels
    gsap.set("." + styles.panel, {
      zIndex: (i, target, targets) => targets.length - i,
    });

    // Animate panels
    const images = gsap.utils.toArray(
      "." + styles.panel + ":not(." + styles.purple + ")"
    );
    images.forEach((image, i) => {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: "section." + styles.black,
            start: () => "top -" + window.innerHeight * (i + 0.5),
            end: () => "+=" + window.innerHeight,
            scrub: true,
            toggleActions: "play none reverse none",
            invalidateOnRefresh: true,
          },
        })
        .to(image, { height: 0 });
    });

    // Set z-index for text panels
    gsap.set("." + styles["panel-text"], {
      zIndex: (i, target, targets) => targets.length - i,
    });

    // Animate text panels
    const texts = gsap.utils.toArray("." + styles["panel-text"]);
    texts.forEach((text, i) => {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: "section." + styles.black,
            start: () => "top -" + window.innerHeight * (i + 0.25),
            end: () => "+=" + window.innerHeight,
            scrub: true,
            toggleActions: "play none reverse none",
            invalidateOnRefresh: true,
          },
        })
        .to(text, { duration: 0.33, opacity: 1, y: "50%" })
        .to(text, { duration: 0.33, opacity: 0, y: "0%" }, 0.66);
    });

    // Pin the black section and set total scroll length
    ScrollTrigger.create({
      trigger: "section." + styles.black,
      scrub: true,
      markers: false,
      pin: true,
      start: "top top",
      end: () => "+=" + (images.length + 1) * window.innerHeight,
      invalidateOnRefresh: true,
    });

    // Cleanup function to kill ScrollTriggers on unmount
    return () => {
      ScrollTrigger.getAll().forEach((st) => st.kill());
    };
  }, []);

  return (
    <>
      <section className={styles.black}>
        <div className={styles["text-wrap"]}>
          <div className={`${styles["panel-text"]} ${styles["blue-text"]}`}>
            <h1>Brand Profile</h1>
            <p>
              A Brand Profile is a comprehensive overview or representation of a
              brand, typically used to convey its identity, values, mission, and
              key attributes to stakeholders, customers, or the public. It
              serves as a key reference point for understanding what the brand
              stands for and how it positions itself in the market.
            </p>
          </div>
          <div className={`${styles["panel-text"]} ${styles["red-text"]}`}>
            <h1>Campaign View for Brands</h1>
            <p>
              The Campaign View for Brands is a feature in platforms (such as
              influencer marketing or digital marketing platforms) that provides
              brands with detailed insights and tools to manage, monitor, and
              analyze their marketing campaigns. It offers a real-time overview
              of all ongoing and completed campaigns, helping brands track
              performance, engagement, and outcomes.
            </p>
          </div>
          <div className={`${styles["panel-text"]} ${styles["orange-text"]}`}>
            <h1>Chat Page</h1>
            <p>
              A chat page is a web or mobile application interface that allows
              users to communicate with each other in real-time through text
              messages, audio, video, or multimedia content. Chat pages are
              commonly found in messaging apps, customer support platforms,
              social media, and collaborative platforms.
            </p>
          </div>
          <div className={`${styles["panel-text"]} ${styles["purple-text"]}`}>
            <h1>Request Page</h1>
            <p>
              A Request Page in the context of your influencer marketing
              platform would be a section where brands can send collaboration
              requests to influencers or manage requests from influencers. It
              serves as a hub for communication and negotiation between brands
              and influencers regarding campaign details, deliverables, and
              agreements.
            </p>
          </div>
        </div>

        <div className={styles["p-wrap"]}>
          <div className={`${styles.panel} ${styles.blue}`}></div>
          <div className={`${styles.panel} ${styles.red}`}></div>
          <div className={`${styles.panel} ${styles.orange}`}></div>
          <div className={`${styles.panel} ${styles.purple}`}></div>
        </div>
      </section>
    </>
  );
}

export default Scroller;
