// src/components/LineChart/LineChart.jsx

import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styles from "./LineChart.module.css";

const data = [
  { name: "9am", dataset1: 30, dataset2: 20 },
  { name: "11am", dataset1: 200, dataset2: 250 },
  { name: "1pm", dataset1: 150, dataset2: 40 },
  { name: "3pm", dataset1: 100, dataset2: 80 },
  { name: "5pm", dataset1: 0, dataset2: 60 },
  { name: "7pm", dataset1: 0, dataset2: 70 },
  { name: "9pm", dataset1: 0, dataset2: 100 },
];

const CustomLineChart = () => {
  return (
    <div className={styles.chartContainer}>
      <h2 className={styles.title}>Recharts Line Chart</h2>
      <ResponsiveContainer width="100%" height={400}>
      <AreaChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="0 0" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="dataset1" stroke="#EA0063" fill="#D9D9D9" />

          <CartesianGrid strokeDasharray="0 0" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="dataset2" stroke="#8884d8" fill="#8884d8" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomLineChart;
