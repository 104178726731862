import React from "react";
import styles from "./Account.module.css";
import {  useSelector } from "react-redux";
const Account = () => {
  const authData = useSelector((state) => state.auth.authData.user);
 

  return (
    <div className={styles.accountContainer}>
      {/* Account Information Section */}
      <div className={styles.section}>
        <h1>Account Information</h1>
        <div className={styles.infoRow}>
          <div className={styles.label}>Name</div>
          <div className={styles.value}>
            {authData.displayname} {/**  Name */}
          </div>
          <div className={styles.edit}></div>
        </div>

        <div className={styles.infoRow}>
          <div className={styles.label}>Email</div>
          <div className={styles.value}>
            {authData.email} {/**  Email */}
            <span className={styles.primary}>Primary</span>
          </div>
          <div className={styles.edit}>{authData.isVerified?"Verified":"Verify"}</div>
        </div>

        <div className={styles.infoRow}>
          <div className={styles.label}>Primary Platform</div>
          <div className={styles.value}>
            {authData.primaryPlatform} {/**  Primary Platform */}
          </div>
          <div className={styles.edit}>Edit</div>
        </div>

        <div className={styles.infoRow}>
          <div className={styles.label}>Profile Link</div>
          <div className={styles.value}>
            {authData.profileLink} {/**  Profile link */}
          </div>
          <div className={styles.edit}>link</div>
        </div>

        <div className={styles.infoRow}>
          <div className={styles.label}>Type of User</div>
          <div className={styles.value}>
            {authData.userType} {/**  usertype */}
          </div>
          <div className={styles.edit}></div>
        </div>

        <div className={styles.infoRow}>
          <div className={styles.label}>Niches</div>
          <div className={styles.value}>
            {authData.niche.join(", ")}

            {/**  niches */}
          </div>
          <div className={styles.edit}>Edit</div>
        </div>

        <div className={styles.infoRow}>
          <div className={styles.label}>Password</div>
          <div className={styles.value}>
            <span className={styles.changePassword}>Change Password</span>
          </div>
          <div className={styles.edit}></div>
        </div>
      </div>

      {/* Social Account Section */}
      <div className={styles.section}>
        <h1>Social Account</h1>
        <div className={styles.socialRow}>
          <div className={styles.icon}>
            <img
              src="https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kNS0xMF8xLnBuZw.png"
              alt="LinkedIn"
            />
          </div>
          <div className={styles.socialLabel}>LinkedIn</div>
          <div className={styles.socialStatus}>Not Connected</div>
          <div className={styles.socialAction}>Connect</div>
        </div>
        <div className={styles.socialRow}>
          <div className={styles.icon}>
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoY7MdBcwHkN6nwm5oN3PKA0fZMT0nTvaD2g&s"
              alt="Google"
            />
          </div>
          <div className={styles.socialLabel}>Google</div>
          <div className={styles.socialStatus}>Not Connected</div>
          <div className={styles.socialAction}>Connect</div>
        </div>

        <div className={styles.socialRow}>
          <div className={styles.icon}>
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjvzC_QRv6moAhgNb5C6e3yicKgFND1g2RwA&s"
              alt="Facebook"
            />
          </div>
          <div className={styles.socialLabel}>Facebook</div>
          <div className={styles.socialStatus}>Not Connected</div>
          <div className={styles.socialAction}>Connect</div>
        </div>

        <div className={styles.socialRow}>
          <div className={styles.icon}>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png"
              alt="Instagram"
            />
          </div>
          <div className={styles.socialLabel}>Instagram</div>
          <div className={styles.socialStatus}>Not Connected</div>
          <div className={styles.socialAction}>Connected</div>
        </div>
      </div>
    </div>
  );
};

export default Account;
