import toast from "react-hot-toast";
import * as BrandAPI from "../api/BrandRequests";

// Define the action types as constants
export const GET_CREATORS_REQUEST = "GET_CREATORS_REQUEST";
export const GET_CREATORS_SUCCESS = "GET_CREATORS_SUCCESS";
export const GET_CREATORS_FAILURE = "GET_CREATORS_FAILURE";

// Action to get creators
export const RequestedCreator = (CreatorData) => async (dispatch) => {
  dispatch({ type: GET_CREATORS_REQUEST });

  try {
    const response = await BrandAPI.RequestedCreator(CreatorData);
    
    if (response.data.success) {
     // console.log(response.data);
    //  dispatch({ type: GET_CREATORS_SUCCESS, data: response.data });
      toast.success(response.data.message);
      return response.data;
    }  else if (!response.data.success) {
      dispatch({ type: GET_CREATORS_FAILURE, data: response.data  });
      console.log(response.data);
      toast.error(response.data.message);
    }
  } catch (error) {
    dispatch({ type: GET_CREATORS_FAILURE });
    console.log(error);
    toast.error(error.response.data.message);
  }
};

export const GetRequestedList = (id) => async (dispatch) => {
  dispatch({ type: GET_CREATORS_REQUEST });

  try {
    const response = await BrandAPI.GetRequestedList(id);
    if(!response.data.success){
      toast.error(response.data.message);
    }
    if (response.data.success) {
      //console.log(response.data);
      dispatch({ type: GET_CREATORS_SUCCESS, data: response.data });
    
    } 
    return response.data;
  } catch (error) {
    dispatch({ type: GET_CREATORS_FAILURE });
    console.log(error);
    toast.error(error.response.data.message);
  }
};



export const deleteRequestedInfluencer = (brandID,creatorID) => async (dispatch) => {
  dispatch({ type: GET_CREATORS_REQUEST });
 // console.log(creatorID)
  
  try {
    const response = await BrandAPI.deleteRequestedInfluencer(brandID,creatorID);
    console.log(response);
    if(!response.data.success){
      toast.error(response.data.message);
    }
    return response.data;
  } catch (error) {
    dispatch({ type: GET_CREATORS_FAILURE });
    console.log(error);
    toast.error(error.response.data.message);
  }
};

