import React from 'react'
import styles from './Brands.module.css';

// Brands assets
import ceat from "../../../assets/brands/ceat.png";
import ajio from "../../../assets/brands/ajio.png";
import dbs from "../../../assets/brands/dbs.png";
import tata from "../../../assets/brands/tata.png";
import magicbrush from "../../../assets/brands/magicbrush.png";
import gym from "../../../assets/brands/gym.png";
import studio from '../../../assets/brands/studio.png'



const Brands = () => {
  return (
    <div className={styles['brands-section']}>
      <h1>Brands We've Worked With</h1>
      <div className={styles['brands-logos']}>
        <div className={styles['brand-logo']}>
          <img src={ceat} alt="Logo 1" />
        </div>
        <div className={styles['brand-logo']}>
          <img src={ajio} alt="Logo 2" />
        </div>
        <div className={styles['brand-logo']}>
          <img src={dbs} alt="Logo 3" />
        </div>
        <div className={styles['brand-logo']}>
          <img src={magicbrush} alt="Logo 4" />
        </div>
        <div className={styles['brand-logo']}>
          <img src={tata} alt="Logo 5" />
        </div>
        <div className={styles['brand-logo']}>
          <img src={gym} alt="Logo 6" />
        </div>
        <div className={styles['brand-logo']}>
          <img src={studio} alt="Logo 7" />
        </div>
      </div>
    </div>
  );
};

export default Brands;
