import React from 'react';
import styles from './Analytics.module.css';

const Analytics = () => {
    return (
        <div className={styles.analyticsContainer}>
            <h2>Analytics Content</h2>
            <p>This is where the analytics details will be displayed.</p>
        </div>
    );
};

export default Analytics;
