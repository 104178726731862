import React from "react";
import styles from "./CampaignPerformance.module.css";

const CampaignPerformance = () => {
  const peopleData = [
    {
      name: "Barsha Mishra",
      email: "barshamishra@gmail.com",
      views: "534,917+ Views",
      imageUrl: "https://placekitten.com/50/50", // Placeholder image, replace with the actual image URL
    },
    {
      name: "Ashish Mohapatra",
      email: "ashishmohapatra240@gmail.com",
      views: "521,778+ Views",
      imageUrl: "https://placekitten.com/50/50", // Placeholder image, replace with the actual image URL
    },
    {
      name: "Suryansu Dash",
      email: "suryansudash@gmail.com",
      views: "43,267+ Views",
      imageUrl: "https://placekitten.com/50/50", // Placeholder image, replace with the actual image URL
    },
    {
      name: "Mansha Rajput",
      email: "mansharajput@gmail.com",
      views: "26,789+ Views",
      imageUrl: "https://placekitten.com/50/50", // Placeholder image, replace with the actual image URL
    },
    {
      name: "Rupesh Mishra",
      email: "rupeshm@gmail.com",
      views: "18,990+ Views",
      imageUrl: "https://placekitten.com/50/50", // Placeholder image, replace with the actual image URL
    },
  ];

  return (
    <div className={styles.campaignContainer}>
      <div className={styles.campaignViews}>
        <div>
          <h4>Compaign</h4>
          <p>View your Campaign Details</p>
        </div>
        <div>
          <p className={styles.AllTime}>All Time</p>
        </div>
      </div>

      <div className={styles.CreatorDetailsBox}>
      {peopleData.map((person, index) => (
        <div  key={index} className={styles.CreatorDetail}>
          <div className={styles.CreatorBasics}>
            <div>
            
              <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" alt="profile" />
            </div>

            <div className={styles.CreatorName}>
              <p style={{fontWeight:"bold"}}>{person.name}</p>
              <p> {person.email}</p>
            </div>
          </div>

          <div className={styles.CreatorViews}>  {person.views}</div>
        </div>
      ))}
      </div>
    </div>
  );
};

export default CampaignPerformance;
