// Footer.js
import React, { useEffect } from "react";
import styles from "./Footer.module.css"; // Assume we've created a corresponding CSS module
import logo2 from "../../assets/logo2.png";

import { ImTwitter } from "react-icons/im";
import { PiInstagramLogoFill } from "react-icons/pi";
import { FaFacebookF,FaLinkedin  } from "react-icons/fa";

const Footer = () => {
 
  return (
    <>
      <div className={styles.footerContainer}>
        <div className={styles.footerSectionLeft}>
          <h3 className={styles.footerTitle}>
            <img src={logo2} alt="inggage" />
          </h3>
          <div className={styles.SocialIcon}>
            {/**Put Social Media Logo */}
            <a href="https://www.instagram.com/inggage/" target="_blank">
              <PiInstagramLogoFill className={styles.Icon} />
            </a>
            <a href="https://www.instagram.com/inggage/" target="_blank">
              <FaFacebookF className={styles.Icon} />
            </a>
            <a href="https://www.instagram.com/inggage/" target="_blank">
              <ImTwitter className={styles.Icon} />
            </a>
            <a href="https://www.linkedin.com/company/inggage/" target="_blank">
              <FaLinkedin  className={styles.Icon} />
            </a>
          </div>
          <p className={styles.footerText}>
            Discover data-driven insights with our Sales Report SaaS platform.
            Transform your sales data into actionable insights.
          </p>
        </div>
        <div className={styles.footerSectionRight}>
          <div className={styles.footerSection}>
            <h4 className={styles.footerTitle}>Information</h4>
            <ul className={styles.footerList}>
              <li className={styles.footerItem}>FAQ</li>
              <li className={styles.footerItem}>Blog</li>
              <li className={styles.footerItem}>Support</li>
              <li className={styles.footerItem}>Help</li>
            </ul>
          </div>
          <div className={styles.footerSection}>
            <h4 className={styles.footerTitle}>Company</h4>
            <ul className={styles.footerList}>
              <li className={styles.footerItem}>About us</li>
              <li className={styles.footerItem}>Careers</li>
              <li className={styles.footerItem}>Contact us</li>
              <li className={styles.footerItem}>Features</li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.FooterBottom}>
        <div className={styles.footerName}>
          <div>
            <p>@ Inggage 2024</p>
          </div>
        </div>
        <div className={styles.footerLink}>
          <div>
            <p>Terms</p>
            <p>Privacy</p>
            <p>Policies</p>
          </div>
        </div>
        <div className={styles.footerBtn}>
          <div>
            <ImTwitter />
            <PiInstagramLogoFill />
            <FaFacebookF />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
