import React from "react";
import styles from "./CampaignColl.module.css";

const CampaignColl = () => {
  const campaigns = [
    { title: "Har Ghar Modi", count: 26 },
    { title: "Har Ghar Modi", count: 26 },
    { title: "Har Ghar Modi", count: 26 },
    { title: "Go To Collection", count: 16 },
  ];

  return (
    <div className={styles.Collectioncontainer}>
      <div className={styles.cardContainer}>
        {campaigns.map((campaign, index) => (
          <div key={index} className={styles.card}>
            <div>
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjk2eRITDWMjwT9FQUjdYrslekdtBzR4ZqHw&s"
                alt="CampaignThumbnail"
              ></img>
            </div>
            <div className={styles.cardContent}>
              <div>
                <p className={styles.CampaignName}>{campaign.title}</p>
              </div>
              <div>
                {" "}
                <p className={styles.CreatorCount}>{campaign.count} influencers</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CampaignColl;
