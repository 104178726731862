import React, { useState, useEffect } from "react";
import styles from "./Interests.module.css";
import { updateInterests, updateUser } from "../../../actions/UploadAction.js";
import { useDispatch ,useSelector} from "react-redux";
import { useParams } from "react-router-dom"; // Import useParams

const Interests = () => {
  // State for active category and selected interests
  const user = useSelector((state) => state.auth.authData.user); 
  const [activeCategory, setActiveCategory] = useState("Art");

  const [selectedInterests, setSelectedInterests] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();

  // Initial formData with interests as an empty array
  const [formData, setFormData] = useState({
    influencerId: id,
    interests: [],
  });

  
  // Data for interests in different categories
  const categories = {
    Art: [
      "Actor",
      "Artist",
      "Author",
      "Book Critic",
      "Comedian",
      "Dancer",
      "DJ",
      "Film Critic",
      "Film Director",
      "Musician",
      "Photographer",
      "Writer",
    ],
    Business: [
      "Entrepreneur",
      "Business Analyst",
      "Consultant",
      "Investor",
      "Trader",
    ],
    Lifestyle: [
      "Blogger",
      "Chef",
      "Traveler",
      "Fitness Enthusiast",
      "Influencer",
    ],
    Sports: ["Footballer", "Cricketer", "Coach", "Referee"],
    Technology: [
      "Developer",
      "Designer",
      "Engineer",
      "Product Manager",
      "Data Scientist",
    ],
  };

  // Handle selecting/deselecting an interest
  const handleInterestClick = (interest) => {
    if (selectedInterests.includes(interest)) {
      // If already selected, deselect it
      setSelectedInterests(
        selectedInterests.filter((item) => item !== interest)
      );
    } else {
      // Select it if less than 5 interests are selected
      if (selectedInterests.length < 5) {
        setSelectedInterests([...selectedInterests, interest]);
      }
    }
  };

  // Handle form submission and save to localStorage
  const handleSubmit = (e) => {
    e.preventDefault();

    // Update formData with the selected interests
    const updatedFormData = { ...formData, interests: selectedInterests };

    // Update formData state
    setFormData(updatedFormData);

    // Save to localStorage
    localStorage.setItem("interests", JSON.stringify(updatedFormData));
    alert("Form submitted and saved to localStorage!");

    // You can dispatch the form data here if needed
    // dispatch(updateAbout(updatedFormData));
    try {
      // actions/AuthActions
      dispatch(updateUser(id, updatedFormData));
    } catch (err) {
      if (err.response && err.response.status === 401) {
        alert("User already exists with this email.");
      } else {
        console.log(err);
        alert("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <div className={styles.InterestsContainer}>
      <div className={styles.tabContainer}>
        {Object.keys(categories).map((category) => (
          <button
            key={category}
            onClick={() => setActiveCategory(category)}
            className={activeCategory === category ? styles.activeTab : ""}
          >
            {category}
          </button>
        ))}
      </div>

      <div className={styles.interestsList}>
        {categories[activeCategory].map((interest) => (
          <div
            key={interest}
            onClick={() => handleInterestClick(interest)}
            className={
              selectedInterests.includes(interest)
                ? styles.selectedInterest
                : styles.interest
            }
          >
            {interest}
          </div>
        ))}
      </div>

      <form onSubmit={handleSubmit}>
        <button type="submit" disabled={selectedInterests.length === 0}>
          Submit
        </button>
      </form>

      <div className={styles.selectedList}>
        <p>Selected Interests:(upto 5)</p>
        <ul>
          {user.interests.length > 0 ? (
           user.interests.map((interest) => (
              <li key={interest}>{interest}</li>
            ))
          ) : (
            <li>No interests selected.</li> // Message if no interests are selected
          )}
        </ul>
      </div>
    </div>
  );
};

export default Interests;
