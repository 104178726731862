import React, { useState, useEffect , useRef} from "react";
import "./App.css";
import { useSelector, useDispatch } from "react-redux";
import { GetAll_Creators } from "./actions/CreatorsActions";
import { GetRequestedList } from "./actions/BrandActions";
import LandingPage from "./pages/LandingPage/LandingPage";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import SignupForm from "./components/SignupForm/SignupForm";
import SignupForm2 from "./components/SignUpForm2/SignupForm2";
import Login from "./components/Login/Login";
import Insta from "./components/instagram/Instagram";
import CampaignDashboard from "./components/CampaignDashboard/CampaignDashboard";
import Talent from "./components/TalentPage/Talent";
import InfluencerProfile from "./components/InfluencerProfile/InfluencerProfile";
import BrandProfile from "./components/BrandProfile/BrandProfile";
import UpdateInfluencer from "./components/UpdateInfluencer/UpdateInfluencer";
import Contact from "./pages/Contact/Contact";
import CampaignView from "./components/CampaignView/CampaignView";
import Brand from "./pages/Brand/Brand";
import Creator from "./pages/Creator/Creator";
import Insta2 from "./components/instagram/insta2";
import Admin from "./components/Admin/Admin";
import CreateCampaign from "./components/CreateCampaign/CreateCampaign";
import InfluxDB from "./components/InfluxDB/InfluxDB";


const App = () => {
 
  const dispatch = useDispatch();
  const [creatorss, setCreators] = useState([]);
  const [isBrand, setisBrand] = useState(false);
  const [Requests, setRequests] = useState(null);
  const authData = useSelector((state) => state.auth.authData);
  const [connectedUsers, setConnectedUsers] = useState([]);


  const wsRef = useRef(null);

  // useEffect(() => {
  //   if (authData && authData.token) {
  //     // Only open a WebSocket connection if one doesn't already exist
  //     if (!wsRef.current) {
  //       wsRef.current = new WebSocket('ws://localhost:4000/ws/user');
  //     }

  //     // On WebSocket open, authenticate with the token
  //     wsRef.current.onopen = () => {
  //       wsRef.current.send(JSON.stringify({ token: authData.token }));
  //     };

  //     // On receiving messages, update connected users
  //     wsRef.current.onmessage = (event) => {
  //       const { userId, status } = JSON.parse(event.data);
  //       // console.log("userid", userId);
  //       // console.log(status)
  //       setConnectedUsers((prev) =>
  //         prev.map((u) => (u.id === userId ? { ...u, status } : u))
  //       );
  //     };

  //     //console.log(connectedUsers);

  //     // Cleanup WebSocket on component unmount
  //     return () => {
  //       if (wsRef.current) {
  //         wsRef.current.close();
  //         wsRef.current = null;
  //       }
  //     };
  //   }
  // }, [authData]);

  // useEffect(() => {
  //   console.log("Updated connectedUsers:", connectedUsers);
  // }, [connectedUsers]);

 

  useEffect(() => {
    (async function fetchCreators() {
      const result = await dispatch(GetAll_Creators()); // Dispatch action and wait for the result
      setCreators(result); // Set the creators if your result contains the data
    })();
    if (authData && authData.user.userType === "Brand") {
      setisBrand(!isBrand);

      const fetchRequestedList = async () => {
        const id = authData.user._id;
        try {
          const response = await dispatch(GetRequestedList(id));
          //console.log(response)
          setRequests(response.data);
          // You can use `requestedListData` here or store it in state if needed
        } catch (error) {
          console.error("Error fetching requested list:", error);
        }
      };

      // Fetch the requested list when `authData` is available
      fetchRequestedList();
    }
  }, [dispatch]);
  //const brandData = authData.user;

  /// make some condition to define brand is logged in then cart possible

  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>

        <Route path="/influxdb" element={<InfluxDB />}></Route>

          <Route path="/" element={<LandingPage />}></Route>
          <Route path="/admin" element={<Admin />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/Signup" element={<SignupForm />}></Route>
          <Route path="/Signupform" element={<SignupForm2 />}></Route>
          <Route path="/Login" element={<Login />}></Route>
          <Route
            path="/Talent"
            element={
              <Talent
                creator={creatorss}
                isBrand={isBrand}
                brandData={authData}
              />
            }
          ></Route>
          <Route path="/instagram" element={<Insta />}></Route>
          <Route path="/insta2" element={<Insta2 />}></Route>

          <Route
            path="/CampaignDashboard"
            element={<CampaignDashboard />}
          ></Route>
          <Route path="/influencer/:id" element={<InfluencerProfile />}></Route>
          

          <Route
            path="/Brand/:id"
            element={<BrandProfile requestedCreator={Requests} />}
          ></Route>
          <Route path="/Brand/Campaignview" element={<CampaignView />}></Route>

          {authData && (
            <Route
              path="/createcampaign"
              element={
                <CreateCampaign
                  creatorss={creatorss}
                  brandID={authData.user._id}
                />
              }
            ></Route>
          )}

          <Route
            path="/influencer/update/:id"
            element={<UpdateInfluencer />}
          ></Route>

          <Route path="/Contact" element={<Contact />}></Route>
          <Route path="/creator" element={<Creator />}></Route>
          <Route path="/Brand" element={<Brand />}></Route>
        </Routes>
      </Router>
      <Footer />
    </div>
  );
};

export default App;
