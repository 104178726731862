import React, { useState, useEffect } from "react";
import styles from "./About.module.css"; // Import CSS module
import { updateUser } from "../../../actions/UploadAction.js";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom"; // Import useParams
import toast from "react-hot-toast";

const About = () => {
  const user = useSelector((state) => state.auth.authData.user); // Get user data from Redux store

  const dispatch = useDispatch();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    influencerId: id,
    bio: "",
    displayname: "",

    publicLocation: "",
    instaUsername: "",
  });

  // Populate form data from Redux store if available
  useEffect(() => {
    if (user) {
      setFormData({
        influencerId: id,
        bio: user.bio || "", // Use existing bio or set to empty
        displayname: user.displayname || "", // Use existing display name or set to empty

        publicLocation: user.publicLocation || "", // Use existing location or set to empty
        instaUsername: user.instaUsername || "", // Use existing Instagram username or set to empty
      });
    }
  }, [user, id]); // Add user and id as dependencies

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    toast.success("Form data saved!");

    try {
      dispatch(updateUser(id, formData));
    } catch (err) {
      if (err.response && err.response.status === 401) {
        alert("User already exists with this email.");
      } else {
        console.log(err);
        alert("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <div className={styles.AboutContainer}>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Influencer Name</label>
          <input
            type="text"
            name="displayname"
            value={formData.displayname}
            onChange={handleChange}
            placeholder="Enter influencer name"
          />
        </div>

        <div>
          <label>Bio</label>
          <textarea
            type="text"
            name="bio"
            value={formData.bio}
            onChange={handleChange}
            placeholder="Enter your bio"
          />
        </div>
        <div>
          <label>Instagram Username</label>
          <input
            type="text"
            name="instaUsername"
            value={formData.instaUsername}
            onChange={handleChange}
            placeholder="Enter Instagram username"
          />
        </div>

        <div>
          <label>Public Location</label>
          <select
            name="publicLocation"
            value={formData.publicLocation}
            onChange={handleChange}
          >
            <option value="">Select Location</option>
            <option value="Gwalior">Gwalior</option>
            <option value="Delhi">Delhi</option>
            <option value="Mumbai">Mumbai</option>
            <option value="Bangalore">Bangalore</option>
          </select>
        </div>

        <button type="submit">Save</button>
      </form>
    </div>
  );
};

export default About;
