import React from 'react';
import styles from './Testimonials.module.css';
import testimonialsData from './TestimonialsData';


const Testimonials = () => {
  return (
    <div className={styles['testimonials-section']}>
       <div className={styles['section-header']}><h2>Testimonials</h2>
      <p>
        Discover the power of data-driven decision-making with our Sales Report SaaS platform. Our app is
        designed to transform your sales data into actionable insights, providing you with real-time reports,
        advanced analytics.
      </p></div>
      

      <div className={styles['testimonial-cards']}>
        {testimonialsData.map((testimonial, index) => (
          <div className={styles['testimonial-card']} key={index}>
            <div className={styles['testimonial-content']}>
              <div className={styles['testimonial-profile']}>
                <img src={testimonial.image} alt={testimonial.name} />
                <div className={styles['testimonial-info']}>
                  <h3>{testimonial.name}</h3>
                  <span>{testimonial.brand}</span>
                </div>
              </div>
              <p>{testimonial.text}</p>
              <div className={styles['testimonial-rating']}>⭐ {testimonial.rating}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
