import React from "react";
import styles from "./Reporting.module.css";
import { PiBrainFill } from "react-icons/pi";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaPaypal } from "react-icons/fa";
import { BiSolidDashboard } from "react-icons/bi";

const Reporting = () => {
  return (
    <div className={styles["reporting-section"]}>
      <h2>Strategy with Advanced Reporting</h2>
      <p>
        Inggage provides analytics tools that deliver insights for businesses of
        all sizes. With a pay-as-you-go model and transparent pricing, you can
        measure ROI, optimize performance, benchmark success, and gain
        competitive insights.
      </p>

      <div className={styles["reporting-cards"]}>
        <div className={styles["reporting-card"]}>
          <div className={styles["card-icon"]}>
            <BsFillPeopleFill className={styles["icon-image"]} />
          </div>
          <h3>Talent Page</h3>
          <p>
            The talent page is like the one-stop solution to finding the perfect
            influencer/creator for your brand marketing.
          </p>
        </div>

        <div className={styles["reporting-card"]}>
          <div className={styles["card-icon"]}>
            <FaPaypal className={styles["icon-image"]} />
          </div>
          <h3>Performance based Payouts</h3>
          <p>
            Inggage's advanced tracking tools provide real-time performance
            monitoring, easing brands to maximize their ROI and only pay for
            performance.
          </p>
        </div>

        <div className={styles["reporting-card"]}>
          <div className={styles["card-icon"]}>
            <BiSolidDashboard className={styles["icon-image"]} />
          </div>
          <h3>Live Analytics Dashboard</h3>
          <p>
            Live updates on how your campaign is performing, the reach beyond
            simply views, and compare the trends across other campaigns and over
            time, all in full control.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Reporting;
