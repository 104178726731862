import { combineReducers } from "redux";

import authReducer from "./AuthReducer";
import creatorsReducer from "./CreatorsReducer";
//import postReducer from "./PostReducer";
//import chatReducer from "./ChatUserReducer";

 const reducers = combineReducers({
   auth: authReducer,
   creators: creatorsReducer
    // chatReducer
    })

    export default reducers;