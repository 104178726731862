import React, { useState, useEffect } from "react";
import styles from "./Contacts.module.css";
import { useParams } from "react-router-dom"; // Import useParams
import { updateContact, updateUser } from "../../../actions/UploadAction";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

const Contacts = () => {
  const user = useSelector((state) => state.auth.authData.user);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    influencerId: id,
    mobile: "",
    email: "",
    registeredAddress: "",
    city: "",
    state: "",
    pinCode: "",
    gender: "male",
    birthDate: { day: "1", month: "January", year: "1970" },
    socialMedia: "Instagram",
    languages: ["English"],
  });

  useEffect(() => {
    // Check if user data is available and set formData
    if (user) {
      setFormData({
        influencerId: id,
        mobile: user.mobile || "", // Use existing mobile or set to empty
        email: user.email || "", // Use existing email or set to empty
        registeredAddress: user.registeredAddress || "", // Use existing address or set to empty
        city: user.city || "", // Use existing city or set to empty
        state: user.state || "", // Use existing state or set to empty
        pinCode: user.pinCode || "", // Use existing pin code or set to empty
        gender: user.gender || "male", // Use existing gender or default to 'male'
        birthDate: user.birthDate || {
          day: "1",
          month: "January",
          year: "1970",
        }, // Use existing birth date or set to default
        socialMedia: user.socialMedia || "Instagram", // Use existing social media or set to default
        languages: user.languages.length > 0 ? user.languages : ["English"], // Use existing languages or default to ['English']
      });
    }
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGenderChange = (gender) => {
    setFormData({ ...formData, gender });
  };

  const handleBirthDateChange = (name, value) => {
    setFormData({
      ...formData,
      birthDate: { ...formData.birthDate, [name]: value },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    toast.success("Form submitted");

    try {
      // actions/AuthActions
      dispatch(updateUser(id, formData));
    } catch (err) {
      if (err.response && err.response.status === 401) {
        alert("User already exists with this email.");
      } else {
        console.log(err);
        alert("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <div className={styles.ContactContainer}>
      <form onSubmit={handleSubmit}>
        <div className={styles.formLeft}>
          {/* Mobile */}
          <div>
            <label>Mobile</label>
            <input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              placeholder="Enter mobile number"
            />
          </div>

          {/* Email */}
          <div>
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter email"
              readOnly 
            />
             <button type="button" className={styles.VerifyButton}>
      {user.isVerified ? "Verified" : "Verify"}
    </button>
          </div>

          {/* Registered Address */}
          <div>
            <label>Registered Address</label>
            <input
              type="text"
              name="registeredAddress"
              value={formData.registeredAddress}
              onChange={handleInputChange}
              placeholder="Enter address"
            />
          </div>

          {/* City and State */}
          <div className={styles.FormRow}>
            <div>
              <label>City</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                placeholder="Enter city"
              />
            </div>

            <div>
              <label>State</label>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                placeholder="Enter state"
              />
            </div>

            <div>
              <label>Pin Code</label>
              <input
                type="text"
                name="pinCode"
                value={formData.pinCode}
                onChange={handleInputChange}
                placeholder="Enter pin code"
              />
            </div>
          </div>
        </div>
        <div className={styles.formRight}>
          {/* Gender */}
          <div>
            <label>Gender</label>
            <div className={styles.GenderOptions}>
              <button
                type="button"
                className={
                  formData.gender === "male" ? styles.ActiveGender : ""
                }
                onClick={() => handleGenderChange("male")}
              >
                Male
              </button>
              <button
                type="button"
                className={
                  formData.gender === "female" ? styles.ActiveGender : ""
                }
                onClick={() => handleGenderChange("female")}
              >
                Female
              </button>
              <button
                type="button"
                className={
                  formData.gender === "other" ? styles.ActiveGender : ""
                }
                onClick={() => handleGenderChange("other")}
              >
                Other
              </button>
            </div>
          </div>

          {/* Birth Date */}
          <div className={styles.FormRow}>
            <div>
              <label>Birth Date</label>
              <select
                name="day"
                value={formData.birthDate.day}
                onChange={(e) => handleBirthDateChange("day", e.target.value)}
              >
                {[...Array(31)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>

              <select
                name="month"
                value={formData.birthDate.month}
                onChange={(e) => handleBirthDateChange("month", e.target.value)}
              >
                {[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ].map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>

              <select
                name="year"
                value={formData.birthDate.year}
                onChange={(e) => handleBirthDateChange("year", e.target.value)}
              >
                {Array.from({ length: 100 }, (_, i) => 1970 + i).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Social Media */}
          <div>
            <label>Social Media</label>
            <select
              name="socialMedia"
              value={formData.socialMedia}
              onChange={handleInputChange}
            >
              <option value="Instagram">Instagram</option>
              <option value="Twitter">Twitter</option>
              <option value="LinkedIn">LinkedIn</option>
              <option value="Facebook">Facebook</option>
            </select>
          </div>

          {/* Languages */}
          <div>
            <label>Languages</label>
            <select
              name="languages"
              value={formData.languages}
              onChange={handleInputChange}
            >
              <option value="English">English</option>
              <option value="Hindi">Hindi</option>
              <option value="Spanish">Spanish</option>
              <option value="French">French</option>
            </select>
          </div>
          {/* Submit */}
          <button type="submit" className={styles.SubmitButton}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contacts;
