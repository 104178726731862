import React, { useState, useEffect } from "react";
import styles from "./Preferences.module.css";
import { useParams } from "react-router-dom"; // Import useParams to get influencerId
import { useDispatch } from "react-redux";
import { updatePreferences } from "../../../actions/UploadAction.js";

const Preferences = () => {
  const brandCategories = [
    "Clothing & Fashion Brands",
    "Sports & Fitness Brands",
    "Gadgets & Electronics Brands",
    "Toys & Games Brands",
    "Art & Creativity Brands",
    "Skincare & Beauty Brands",
    "Food & Beverage Brands",
    "Finance & Crypto Brands",
    "Social Cause & Well-Being Brands",
    "E-Gaming & Technology Brands",
    "Travel & Hospitality Brands",
    "Media & Entertainment Brands",
    "Apps & Software Brands",
  ];

  useEffect(() => {
    const savedData = localStorage.getItem("preferences");
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);
  // Get influencerId from URL
  const { id } = useParams();
  const dispatch = useDispatch();

  // Initializing formData state
  const [formData, setFormData] = useState({
    influencerId: id,
    preferences: [],
  });

  // Handle selecting/deselecting categories
  const handleCategoryClick = (category) => {
    const updatedPreferences = formData.preferences.includes(category)
      ? formData.preferences.filter((item) => item !== category)
      : formData.preferences.length < 3
      ? [...formData.preferences, category]
      : formData.preferences;

    // Update formData state
    setFormData((prevState) => ({
      ...prevState,
      preferences: updatedPreferences,
    }));
  };

  // Handle form submission and save to localStorage
  const handleSubmit = (e) => {
    e.preventDefault();
    // Save formData to localStorage
    localStorage.setItem("Preferences", JSON.stringify(formData));
    alert("Preferences saved successfully!");

    try {
      // actions/AuthActions
      dispatch(updatePreferences(id, formData));
    } catch (err) {
      if (err.response && err.response.status === 401) {
        alert("User already exists with this email.");
      } else {
        console.log(err);
        alert("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <div className={styles.PreferencesContainer}>
      <h3>Select up to 3 Brand Categories</h3>
      <form onSubmit={handleSubmit}>
        <div className={styles.BrandCategories}>
          {brandCategories.map((category, index) => (
            <div
              key={index}
              className={`${styles.BrandCategory} ${
                formData.preferences.includes(category) ? styles.Selected : ""
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </div>
          ))}
        </div>
        <div>
          <button
            type="submit"
            className={styles.SubmitButton}
            disabled={formData.preferences.length === 0}
          >
            Save Preferences
          </button>
        </div>
      </form>
    </div>
  );
};

export default Preferences;
