const testimonialsData = [
    {
      name: "Suryansu Dash",
      brand: "Nike",
      image: "https://randomuser.me/api/portraits/men/1.jpg", // Replace with your image path
      text: "The examining or considering again of something in order to decide if changes are necessary.",
      rating: 5.0,
    },
    {
      name: "Aarav Singh",
      brand: "Adidas",
      image: "https://randomuser.me/api/portraits/men/2.jpg",
      text: "Great experience working with the team, they made everything smooth and efficient.",
      rating: 4.8,
    },
    {
      name: "Ishita Kapoor",
      brand: "Puma",
      image: "https://randomuser.me/api/portraits/women/3.jpg",
      text: "Their attention to detail and customer service is top-notch, I highly recommend them.",
      rating: 4.9,
    },
    {
      name: "Rohan Mehta",
      brand: "Reebok",
      image: "https://randomuser.me/api/portraits/men/4.jpg",
      text: "The service was quick and the quality exceeded my expectations. Will use again.",
      rating: 5.0,
    },
    {
      name: "Priya Sharma",
      brand: "Under Armour",
      image: "https://randomuser.me/api/portraits/women/5.jpg",
      text: "Professional and reliable service. They truly care about their customers.",
      rating: 4.7,
    },
    {
      name: "Vikram Desai",
      brand: "Asics",
      image: "https://randomuser.me/api/portraits/men/6.jpg",
      text: "Impressive work! They delivered exactly what was promised, highly satisfied.",
      rating: 5.0,
    }
  ];
  
  export default testimonialsData;